<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-select
          v-model="request.siteId"
          size="small"
          :placeholder="t('fields.site')"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
          @change="handleChangeSite()"
        >
          <el-option
            v-for="item in list.sites"
            :key="item.id"
            :label="item.siteName"
            :value="item.id"
          />
        </el-select>
        <el-select
          filterable
          clearable
          v-model="request.affiliateId"
          size="small"
          :placeholder="t('fields.affiliate')"
          class="filter-item"
          style="width: 200px; margin-left: 5px;"
        >
          <el-option
            v-for="item in list.affiliates"
            :key="item.affiliateId"
            :label="item.loginName"
            :value="item.affiliateId"
          />
        </el-select>
        <el-select
          filterable
          clearable
          v-model="request.paymentIds"
          size="small"
          :placeholder="t('fields.paymentName')"
          class="filter-item"
          style="width: 200px; margin-left: 5px;"
        >
          <el-option
            v-for="item in list.paymentInfo"
            :key="item.id"
            :label="item.paymentName"
            :value="item.id"
          />
        </el-select>
        <el-select
          filterable
          clearable
          v-model="request.withdrawPlatformId"
          size="small"
          :placeholder="t('fields.withdrawPlatformName')"
          class="filter-item"
          style="width: 200px; margin-left: 5px;"
        >
          <el-option
            v-for="item in list.siteWithdrawPlatform"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadAffiliateFinancialConfig()"
        >
          {{ t('fields.search') }}
        </el-button>
      </div>
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          v-permission="['sys:affiliate-financial-config:add']"
          @click="showDialog('CREATE')"
        >
          {{ t('fields.add') }}
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="600px"
    >
      <el-form
        ref="affiliateFinancialConfigForm"
        v-loading="uiControl.dialogLoading"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item :label="t('fields.affiliate')" prop="affiliateId">
          <el-select
            filterable
            clearable
            v-model="form.affiliateId"
            size="small"
            :placeholder="t('fields.affiliate')"
            class="filter-item"
            style="width: 350px; margin-bottom: 10px"
          >
            <el-option
              v-for="item in list.affiliates"
              :key="item.affiliateId"
              :label="item.loginName"
              :value="item.affiliateId"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('fields.paymentName')" prop="paymentIds">
          <el-select
            filterable
            multiple
            clearable
            v-model="selected.paymentId"
            size="small"
            :placeholder="t('fields.paymentName')"
            class="filter-item"
            style="width: 350px; margin-bottom: 10px"
            @change="handleChangePayments()"
          >
            <el-option
              v-for="item in list.paymentInfo"
              :key="item.id"
              :label="item.paymentName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('fields.withdrawPlatformName')" prop="withdrawPlatformId">
          <el-select
            filterable
            clearable
            v-model="form.withdrawPlatformId"
            size="small"
            :placeholder="t('fields.withdrawPlatformName')"
            class="filter-item"
            style="width: 350px;"
          >
            <el-option
              v-for="item in list.siteWithdrawPlatform"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">{{ t('fields.cancel') }}</el-button>
          <el-button type="primary" @click="submit">{{ t('fields.confirm') }}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-card class="box-card" shadow="never" style="margin-top: 40px">
      <template #header>
        <div class="clearfix">
          <span class="role-span">{{ t('fields.affiliateFinancialManagement') }}</span>
        </div>
      </template>
      <el-table
        :data="page.records"
        v-loading="page.loading"
        ref="table"
        row-key="id"
        size="small"
        highlight-current-row
        :empty-text="t('fields.noData')"
        style="width: 100%"
      >
        <el-table-column prop="loginName" :label="t('fields.affiliateName')" width="150">
          <template
            #default="scope"
            v-if="hasPermission(['sys:affiliate:detail'])"
          >
            <router-link :to="`details/${scope.row.affiliateId}?site=${scope.row.siteId}`">
              <el-link type="primary">{{ scope.row.loginName }}</el-link>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="affiliateCode" :label="t('fields.affiliateCode')" width="150">
          <template #default="scope">
            <span v-if="scope.row.affiliateCode === null">-</span>
            <span v-if="scope.row.affiliateCode !== null">{{ scope.row.affiliateCode }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentName" :label="t('fields.paymentName')">
          <template #default="scope">
            <span class="line-break">{{ scope.row.paymentName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="t('fields.withdrawPlatformName')" prop="withdrawPlatformName" />
        <el-table-column prop="status" :label="t('fields.status')" v-if="hasPermission(['sys:affiliate-financial-config:update'])">
          <template #default="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#409EFF"
              inactive-color="#F56C6C"
              @change="changeStatus(scope.row, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column :label="t('fields.action')" v-if="hasPermission(['sys:affiliate-financial-config:update'])">
          <template #default="scope">
            <el-button
              icon="el-icon-edit"
              size="mini"
              type="success"
              @click="showEdit(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @current-change="changePage"
        layout="prev, pager, next"
        :page-size="request.size"
        :page-count="page.pages"
        :current-page="request.current"
      />
    </el-card>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref, computed } from 'vue'
import { getSiteListSimple } from '../../../api/site'
import { getAffiliateFinancialConfigList, createAffiliateFinancialConfig, updateAffiliateFinancialConfig } from '../../../api/affiliate-financial-config'
import { getWithdrawPlatformsSimpleBySiteId } from "../../../api/withdraw-platform";
import { getSiteWithdrawPlatform } from "../../../api/site-withdraw-platform";
import { getAffiliateList } from '../../../api/affiliate-record'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import { useStore } from '../../../store'
import { useI18n } from "vue-i18n";
import { TENANT } from '../../../store/modules/user/action-types'
import { hasPermission } from '../../../utils/util'
import { getPaymentsSimpleBySiteId } from "../../../api/payment-display";

const { t } = useI18n()
const store = useStore()
const LOGIN_USER_TYPE = computed(() => store.state.user.userType);
const site = ref(null);
const affiliateFinancialConfigForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  dialogLoading: false,
})
const request = reactive({
  size: 30,
  current: 1,
  siteId: null,
})
const list = reactive({
  sites: [],
  withdrawPlatform: [],
  siteWithdrawPlatform: [],
  paymentInfo: [],
  affiliates: [],
})
const page = reactive({
  pages: 1,
  records: [],
  loading: false,
})
const form = reactive({
  id: null,
  affiliateCode: null,
  paymentIds: null,
  status: true,
  withdrawPlatformId: null,
  affiliateId: null,
})
const formRules = reactive({
  affiliateId: [required(t('message.validateAffiliateNameRequired'))],
  paymentIds: [required(t('message.validatePaymentNameRequired'))],
  withdrawPlatformId: [required(t('message.validateWithdrawPlatformNameRequired'))],
})
const selected = reactive({ paymentId: [] })

async function loadSites() {
  const { data: ret } = await getSiteListSimple()
  list.sites = ret
}

async function loadPayment() {
  const { data: ret } = await getPaymentsSimpleBySiteId(request.siteId)
  list.paymentInfo = ret
}

async function loadAffiliateFinancialConfig() {
  const { data: ret } = await getAffiliateFinancialConfigList(request)
  page.records = ret.records
  page.pages = ret.pages
  page.records.forEach(item => {
    item.paymentName = item.paymentName.split(",").join("\n")
  })
}

async function loadSiteWithdrawPlatform(siteId) {
  const { data: ret } = await getSiteWithdrawPlatform(siteId);
  list.siteWithdrawPlatform = ret;
  list.siteWithdrawPlatform.forEach(platform => {
    const matchingItem = list.withdrawPlatform.find(item => item.id === platform.withdrawPlatformId);
    if (matchingItem) {
      platform.name = matchingItem.name;
      platform.id = matchingItem.id;
    }
  });
}

async function loadWithdrawPlatform() {
  const { data: ret } = await getWithdrawPlatformsSimpleBySiteId(request.siteId);
  list.withdrawPlatform = ret
}

async function loadAffiliates() {
  const { data: ret } = await getAffiliateList(request.siteId);
  list.affiliates = ret
}

async function handleChangeSite() {
  await loadAffiliateFinancialConfig()
  await loadPayment()
  await loadWithdrawPlatform()
  await loadSiteWithdrawPlatform(request.siteId)
  await loadAffiliates()
}

function handleChangePayments() {
  form.paymentIds = selected.paymentId.join(',')
}

function changeStatus(data, status) {
  data.status = status
  updateAffiliateFinancialConfig(data)
}

function showEdit(data) {
  showDialog('EDIT')
  nextTick(() => {
    for (const key in data) {
      if (Object.keys(form).find(k => k === key)) {
        form[key] = data[key]
      }
    }
    selected.paymentId = []
    if (form.paymentIds !== null) {
      const paymentIdList = form.paymentIds.split(',')
      paymentIdList.forEach(element => {
        selected.paymentId.push(Number(element))
      })
    }
  })
}

function showDialog(type) {
  if (type === 'CREATE') {
    if (affiliateFinancialConfigForm.value) {
      affiliateFinancialConfigForm.value.resetFields()
    }
    form.id = null
    selected.paymentId = []
    uiControl.dialogTitle = t('fields.addAffiliateFinancialConfig')
  } else if (type === 'EDIT') {
    uiControl.dialogTitle = t('fields.editAffiliateFinancialConfig')
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function create() {
  affiliateFinancialConfigForm.value.validate(async valid => {
    if (valid) {
      form.siteId = request.siteId
      form.status = true
      await createAffiliateFinancialConfig(form)
      uiControl.dialogVisible = false
      await loadAffiliateFinancialConfig()
      ElMessage({ message: t('message.addSuccess'), type: 'success' })
    }
  })
}

function edit() {
  affiliateFinancialConfigForm.value.validate(async valid => {
    if (valid) {
      form.siteId = request.siteId
      form.status = true
      await updateAffiliateFinancialConfig(form)
      uiControl.dialogVisible = false
      await loadAffiliateFinancialConfig()
      ElMessage({ message: t('message.editSuccess'), type: 'success' })
    }
  })
}

function submit() {
  uiControl.dialogLoading = true
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else if (uiControl.dialogType === 'EDIT') {
    edit()
  }
  uiControl.dialogLoading = false
}

function changePage(page) {
  if (request.current >= 1) {
    request.current = page
    loadAffiliateFinancialConfig()
  }
}

onMounted(async() => {
  await loadSites()
  if (LOGIN_USER_TYPE.value === TENANT.value) {
    site.value = list.sites.find(s => s.siteName === store.state.user.siteName);
    request.siteId = site.value.id;
  } else {
    site.value = list.sites[0];
    request.siteId = site.value.id;
  }
  await loadAffiliates()
  await loadWithdrawPlatform()
  await loadSiteWithdrawPlatform(request.siteId)
  await loadPayment()
  await loadAffiliateFinancialConfig()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

:deep(.el-radio__input) {
  display: none !important;
}

:deep(.el-radio.is-bordered.is-checked) {
  background-color: var(--el-color-primary);
}

:deep(.is-checked .el-radio__label) {
  color: white;
}

.el-radio {
  margin-right: 10px;
  margin-bottom: 5px;
}

.el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 0;
}

.form-border {
  border-color: #dcdfe6;
  border-style: solid;
  border-width: 1px
}

.form-header {
  color: white;
  background-color: var(--el-color-primary);
  padding: 10px;
}

.form-body {
  padding: 10px;
}

.el-icon-caret-top {
  color: red;
}

.el-icon-caret-bottom {
  color: green;
}

.line-break {
  white-space: pre;
}
</style>
