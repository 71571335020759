<template>
  <div>
    <el-form ref="formRef" :model="formData" :rules="rules" class="combined-form" label-width="auto">
      <h2>监控设置</h2>
      <el-form-item v-if="false" label="标题">
        <el-input v-model="formData.monitorSetting.title" />
      </el-form-item>
      <el-form-item label="通知生成人数上限阈值" prop="monitorSetting.setting.notificationGenerationUserNumberUpperThreshold">
        <el-input-number :min="2" v-model="formData.monitorSetting.setting.notificationGenerationUserNumberUpperThreshold" />
      </el-form-item>
      <el-form-item label="通知生成人数下限阈值" prop="monitorSetting.setting.notificationGenerationUserNumberLowerThreshold">
        <el-input-number :min="1" v-model="formData.monitorSetting.setting.notificationGenerationUserNumberLowerThreshold" />
      </el-form-item>
      <el-form-item label="状态" prop="monitorSetting.status">
        <el-switch
          :value="formData.monitorSetting.status === 1"
          active-text="启用"
          inactive-text="禁用"
          @change="toggleStatus"
        />
      </el-form-item>

      <h2>通知设置</h2>
      <el-form-item label="通知内文(当高于上限)" prop="notificationSetting.upperContent">
        <el-input
          type="textarea"
          v-model="formData.notificationSetting.upperContent"
          rows="4"
          placeholder="请输入通知内容"
        />
      </el-form-item>
      <el-form-item label="通知内文(当低于下限)" prop="notificationSetting.lowerContent">
        <el-input
          type="textarea"
          v-model="formData.notificationSetting.lowerContent"
          rows="4"
          placeholder="请输入通知内容"
        />
      </el-form-item>
      <el-form-item label="发送频率(分钟)" prop="notificationSetting.setting.backgroundNoticeIntervalMinutes">
        <el-input-number :min="0" v-model="formData.notificationSetting.setting.backgroundNoticeIntervalMinutes" />
      </el-form-item>
      <RoleUserSelector
        ref="roleUserSelectorRef"
        :siteId="store.state.user.siteId"
        :systemRoleIdListToSendNotification="formData.notificationSetting.setting.systemRoleIdListToSendNotification"
        :systemUserIdListToExclude="formData.notificationSetting.setting.systemUserIdListToExclude"
        :telegramUserIdToSendNotification="formData.notificationSetting.setting.telegramUserIdToSendNotification"
      />
      <el-form-item label="跳转页面路径" prop="notificationSetting.redirectionPath">
        <el-input v-model="formData.notificationSetting.redirectionPath" />
      </el-form-item>
      <el-form-item label="状态" prop="notificationSetting.status">
        <el-switch
          :value="formData.notificationSetting.status === 1"
          @change="toggleStatus"
          active-text="启用"
          inactive-text="禁用"
        />
      </el-form-item>
      <el-row justify="center">
        <el-col :span="6">
          <el-button type="primary" @click="submitForm" style="width: 100%">送出</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { ElMessage } from "element-plus";
import { useStore } from "@/store";
import { cloneDeep } from 'lodash';
import { createMonitorSetting, updateMonitorSetting, createNotificationSetting, updateNotificationSetting } from "@/api/monitor-notification";
import RoleUserSelector from "@/views/system/monitor-notification/dialog-custom-content/component/roleUserSelector.vue";

const roleUserSelectorRef = ref(null);
const store = useStore();
const emit = defineEmits(['submitting', 'submitSuccess', 'submitFailed']);
const props = defineProps({
  currentItem: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: true,
  }
});

// 根据 mode 初始化 formData
const formData = ref(props.mode === 'create' ? initializeFormData() : assignFormData());

function initializeFormData() {
  return {
    monitorSetting: {
      title: 'MEMBER_STATISTICS',
      siteId: store.state.user.siteId,
      setting: {
        notificationGenerationUserNumberUpperThreshold: 100,
        notificationGenerationUserNumberLowerThreshold: 20
      },
      status: 1,
    },
    notificationSetting: {
      title: 'MEMBER_STATISTICS',
      siteId: store.state.user.siteId,
      upperContent: '',
      lowerContent: '',
      setting: {
        systemRoleIdListToSendNotification: [],
        systemUserIdListToExclude: [],
        telegramUserIdToSendNotification: [],
        backgroundNoticeIntervalMinutes: 30,
      },
      status: 1,
      tgSetting: null,
      lineSetting: null,
      smsSetting: null,
      redirectionPath: '',
    }
  };
}

function assignFormData() {
  const cloneNotificationSetting = cloneDeep(props.currentItem.notificationSetting);
  const contentJson = JSON.parse(cloneNotificationSetting.content);
  cloneNotificationSetting.upperContent = contentJson.upper;
  cloneNotificationSetting.lowerContent = contentJson.lower;
  delete cloneNotificationSetting.content;

  return {
    monitorSetting: cloneDeep(props.currentItem.monitorSetting),
    notificationSetting: cloneNotificationSetting,
  }
}

const formRef = ref(null);

const rules = {
  monitorSetting: {
    setting: {
      notificationGenerationUserNumberUpperThreshold: [
        { required: true, message: '请填写通知生成人数上限阈值', trigger: 'blur' },
        { type: 'number', min: 2, message: '最小值为2', trigger: 'blur' }
      ],
      notificationGenerationUserNumberLowerThreshold: [
        { required: true, message: '请填写通知生成人数下限阈值', trigger: 'blur' },
        { type: 'number', min: 1, message: '最小值为1', trigger: 'blur' }
      ],
    },
    status: [
      { required: true, message: '请选择状态', trigger: 'change' }
    ]
  },
  notificationSetting: {
    upperContent: [
      { required: true, message: '请填写上限通知内容', trigger: 'blur' }
    ],
    lowerContent: [
      { required: true, message: '请填写下限通知内容', trigger: 'blur' }
    ],
    status: [
      { required: true, message: '请选择状态', trigger: 'change' }
    ],
    setting: {
      backgroundNoticeIntervalMinutes: [
        { required: true, message: '请填写发送频率(分钟)', trigger: 'blur' },
        { type: 'number', min: 30, message: '最小值为30', trigger: 'blur' }
      ]
    }
  }
};

const submitForm = async () => {
  const valid = await formRef.value.validate();
  const upperLowerValid = validateUpperLowerThreshold();
  if (!valid || !upperLowerValid) {
    return;
  }

  emit('submitting'); // 通知父元件
  // 整理资料
  const cloneNotificationToSubmit = cloneDeep(formData.value.notificationSetting);
  cloneNotificationToSubmit.setting.systemRoleIdListToSendNotification = roleUserSelectorRef.value.fetchSystemRoleIdListToSendNotification();
  cloneNotificationToSubmit.setting.systemUserIdListToExclude = roleUserSelectorRef.value.fetchSystemUserIdListToExclude();
  cloneNotificationToSubmit.setting.telegramUserIdToSendNotification = roleUserSelectorRef.value.fetchTelegramUserId();

  cloneNotificationToSubmit.content = JSON.stringify({
    upper: formData.value.notificationSetting.upperContent,
    lower: formData.value.notificationSetting.lowerContent,
  })
  delete cloneNotificationToSubmit.upperContent;
  delete cloneNotificationToSubmit.lowerContent;

  const submitMonitorFn = props.mode === 'create' ? createMonitorSetting : updateMonitorSetting;
  const submitNotificationFn = props.mode === 'create' ? createNotificationSetting : updateNotificationSetting;

  try {
    const monitorResponse = await submitMonitorFn(formData.value.monitorSetting);
    if (monitorResponse.code !== 0) {
      throw new Error(`监控设置提交失败, code: ${monitorResponse.code}`);
    }
    const notificationResponse = await submitNotificationFn(cloneNotificationToSubmit);
    if (notificationResponse.code !== 0) {
      throw new Error(`通知设置提交失败, code: ${notificationResponse.code}`);
    }

    emit('submitSuccess'); // 通知父元件
  } catch (error) {
    console.error(error.message)
    emit('submitFailed'); // 通知父元件
  }
};

const validateUpperLowerThreshold = () => {
  const upperThreshold = formData.value.monitorSetting.setting.notificationGenerationUserNumberUpperThreshold;
  const lowerThreshold = formData.value.monitorSetting.setting.notificationGenerationUserNumberLowerThreshold;

  if (upperThreshold <= lowerThreshold) {
    ElMessage.error('上限阈值必须高于下限阈值');
    return false;
  }

  return true;
};

const toggleStatus = (value) => {
  formData.value.monitorSetting.status = value ? 1 : 0;
  formData.value.notificationSetting.status = value ? 1 : 0;
};

</script>

<style scoped lang="scss">

</style>
