export default {
  common: {
    title: 'TF仪表板',
    logout: '登出',
    welcome_system: '欢迎来到后台系统',
    changePassword: '更换密码',
    status: {
      OPEN: '开启',
      CLOSE: '关闭',
      TEST: '测试',
    },
  },
  affiliate: {
    commissionModel: {
      NORMAL: '正常',
      SIMPLE: '普通',
      DETAILS: '详细',
    },
    level: {
      JUNIOR_AFFILIATE: '五级代理',
      SUB_AFFILIATE: '四级代理',
      AFFILIATE: '三级代理',
      SUPER_AFFILIATE: '二级代理',
      MASTER_AFFILIATE: '一级代理',
    },
    timeType: {
      MONTHLY: '每月',
      WEEKLY: '每周',
    },
    status: {
      APPLY: '申请',
      NORMAL: '正常',
      DISABLE: '禁用',
    },
    belongType: {
      OFFICIAL: '官网',
      PACKAGE: '包网',
    },
  },
  types: {
    NORMAL: '正常',
    TEMPORARY: '临时冻结',
    PERMANENT: '永久冻结',
    gameViolation: '违规游戏',
    memberRequest: '会员要求',
    others: '其他',
    TEST: '测试',
    PROMO_TEST: '活动测试',
    AFFILIATE: '代理',
    OUTSIDE: '三方',
  },
  dateType: {
    depositDate: '存款日期',
    finishDate: '完成日期',
    withdrawDate: '提款日期',
    paymentDate: '支付日期',
    regTime: '注册时间',
    recordTime: '记录时间',
  },
  reportName: {
    Aff_Month_Report: '代理月报表',
    Deposit_Record: '存款记录',
    Withdraw_Record: '提款记录',
    Privilege_Record: '优惠记录',
    Finance_Report: '财务记录',
    Summary_Record: '总结记录',
    Summary_Active_Record: '总结活跃记录',
    Summary_Register_Record: '总结注册记录',
    Summary_Fdp_Record: '总结Fdp记录',
    Summary_Withdraw_Record: '总结提款记录',
    Summary_Deposit_Record: '总结存款记录',
    Summary_Member_Record: '总结会员记录',
    Member_Bet_Record: '会员投注记录',
    Member_Bet_Money_Change: '投注账变',
    Daily_Record: '每日记录',
    Platform_Game_Record: '游戏平台记录',
  },
  depositStatus: {
    SUCCESS: '存款成功',
    SUPPLEMENT_SUCCESS: '存款补单成功',
    CLOSED: '已关闭',
    PENDING: '待存款',
  },
  withdrawStatus: {
    ALL: '全部',
    SUCCESS: '支付成功',
    FAIL: '支付失败',
    APPLY: '申请中',
    STEP_1: '审核中',
    STEP_2: '待支付',
    STEP_3: '支付中',
    AUTOPAY: '自动支付中',
    PENDING: '中止',
    REJECT: '驳回',
    SENDING: '发送中',
    WAITING_CALLBACK: '等待回调',
    PAYING: '支付',
    WAITING_AUTO_PAY: '待自动支付',
    FAIL_REVIEW: '失败审核',
    WAITING_RETRY: '等待重试',
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  withdrawReviewType: {
    MANUAL: '手动审核',
    AUTO: '自动审核',
  },
  withdrawRuleType: {
    min: '大于',
    max: '小于',
    withdrawAmount: '提款金额',
    balanceBeforeWithdrawal: '提款前余额',
    balanceAfterWithdrawal: '提款后金额',
    vip: 'vip组别',
    financialLevel: '财务组别',
    todayWithdrawCount: '当天提款笔数',
    profit: '会员盈利',
    risk: '风险等级'
  },
  outboxStatus: {
    0: '未回复',
    1: '已回复',
  },
  editCheckedStatus: {
    0: '未审核',
    1: '已更改',
    2: '审核失败',
  },
  transfer: {
    status: {
      sending: '发送中',
      success: '成功',
      fail: '失败',
    },
    type: {
      deposit: '转入',
      withdraw: '转出',
    },
  },
  moneyChange: {
    type: {
      DEPOSIT: '存款',
      WITHDRAW: '提款',
      PROMO: '优惠',
      TRANSFER: '转账',
      BET: '投注',
      WITHDRAW_FAIL: '提款失败',
      ADJUST: '账变',
      PAYOUT: '派彩',
      ROLLBACK: '回滚',
      PATCH: '修补',
      BET_N_PAYOUT: '投注并派彩',
      AFFILIATE_TRANSFER: '代理代存',
      VIP_REBATE: 'VIP返水',
      AFFILIATE_BET_REBATE: '返水佣金',
    },
    subType: {
      DEPOSIT: '主账号转入平台',
      WITHDRAW: '平台转出主账号',
      TRASNFER_IN: '转入',
      TRANSFER_OUT: '转出',
      AFFILIATE_SETTLEMENT: '代理结算',
      AFFILIATE_DEPOSIT: '额度代存',
      AFFILIATE_COMMISSION: '佣金代存',
      MAIN_WALLET: '主钱包平账',
      COMMISSION_WALLET: '佣金钱包平账',
    },
  },
  status: {
    monthSummary: {
      UNCLEAR: '未结算',
      CLEARING: '结算中',
      CLEARED: '已结算',
    },
    settlement: {
      CHECKING: '审核中',
      PAY: '结算中',
      CLEARED: '已结算',
    },
    member: {
      NORMAL: '正常',
      FROZEN: '冻结',
    },
    gameMatch: {
      ACTIVE: '进行中',
      ENDED: '已结束',
    },
    gameMatchRecord: {
      PENDING_MATCH: '待赛事结束',
      PENDING_SETTLE: '待审核',
      SETTLED: '已发放',
      CANCEL: '已取消',
    },
    gameQuiz: {
      ONGOING: '进行中',
      CANCEL: '已取消',
      ENDED: '已结束',
    },
    gameQuizAnswer: {
      PENDING: '待结算',
      CANCEL: '已取消',
      WIN: '赢',
      LOSE: '输',
    },
    gift: {
      OPEN: '开启',
      CLOSE: '关闭',
    },
    giftRecord: {
      PENDING: '待审核',
      PROCESSING: '审核中',
      COMPLETE: '已发放',
      EXPIRED: '已截止',
      FAILED: '已取消',
    },
    giftOrderRecord: {
      PROCESSING: '审核中',
      DELIVERING: '运送中',
      DELIVERED: '已送达',
      CASH: '已折现',
      CANCEL: '已取消',
    },
    piggyBank: {
      SAVING: '存款中',
      CLAIMED: '已领取',
    },
    uefaMatch: {
      PENDING: '未开始',
      ONGOING: '进行中',
      CANCEL: '已取消',
      ENDED: '已结束',
    },
    uefaMatchRecord: {
      PENDING: '待结算',
      LOSE: '输',
      WIN_MATCH: '竞猜成功',
      WIN_TEAM: '专属队伍获胜',
      WIN_ALL: '全赢',
      CANCEL: '已取消',
    },
    sportMatch: {
      ACTIVE: '进行中',
      ENDED: '已结束',
      SETTLED: '已结算',
    },
  },
  betStatus: {
    BET: '下注',
    UNSETTLED: '未结算',
    SETTLED: '已结算',
    CANCEL: '已取消',
    GIVEREWARD: '打赏',
  },
  giftType: {
    ENTITY: '实体',
    VIRTUAL: '虚拟',
  },
  dashboard: {
    memberCount: '人数',
    totalFirstDeposit: '总首存',
    totalDeposit: '总存款',
    totalTransaction: '笔数',
    totalTransfer: '总代充',
    totalWithdraw: '总提款',
    totalWinLoss: '总公司输赢',
    totalValidBet: '总有效投注',
    totalBonus: '总彩金',
    totalProfit: '总公司盈利',
    returnAmount: '返水金额',
    totalActiveMember: '总活跃会员',
    newRegisterMember: '新注册会员',
    totalVisit: '总访问量',
    depositChartName: '存款总金额',
    depositAmount: '存款金额',
    depositMemberCount: '存款人数',
    depositTransactionCount: '存款笔数',
    depositTransactionNumChartName: '存款总人数/存款总笔数',
    financialLevelRatio: '财务层次占比',
    vipLevelRatio: 'VIP等级占比',
    paymentMethod: '通道类别',
    ratio: '占比',
    fundAmount: '资金量',
    paymentMethodSummary: '支付方式充值汇总',
    total: '合计',
    bet: '投注',
    payout: '结算',
    merchantDepositSummary: '商户充值汇总',
    onlineStatsCompareChartName: '在线人数环比',
    onlineStatsChartName: '在线人数统计',
    rfdRegisterCount: '注册人数统计',
    rfdFDepositCount: '首存人数统计',
    rfdReport: '注册首存走势',
  },
  reportGame: {
    gamePlatform: '游戏平台',
    gameMemberCount: '每日游戏人数',
    gameBetCount: '每日下注笔数',
    gameBetAmount: '每日下注金额',
    gameWinLoss: '每日输/赢金额',
    gameMemberCountTotal: '总游戏人数',
    gameBetCountTotal: '总下注笔数',
    gameBetAmountTotal: '总下注金额',
    gamePayoutAmountTotal: '总派彩金额',
    gameWinLossTotal: '总输/赢金额',
  },
  gameType: {
    ALL: '全部',
    SLOT: '电子',
    LIVE: '真人',
    FISH: '捕鱼',
    SPORT: '体育',
    ESPORT: '电竞',
    POKER: '棋牌',
    LOTTERY: '彩票',
    CASUAL: '小游戏',
    NBA: 'NBA',
    COCKFIGHT: '斗鸡',
    MSI: 'MSI季中冠军赛',
    COPA: '美洲杯',
    UEFA: '欧足联',
    UCL: '欧洲冠军联赛',
    HOTELCASINO: '酒店真人',
  },
  homeBannerType: {
    HOME: '首页',
    LIVE: '真人',
    SLOT: '电子',
    FISH: '捕鱼',
    POKER: '棋牌',
    PROMO: '优惠',
    HOMEPROMO: '首页优惠',
    HOMEPOP: '首页弹出窗',
    CENTERPROMO: '中心优惠',
    LOGIN: '登录',
    REGISTER: '注册',
    VIP: 'VIP',
  },
  distributeStatus: {
    PENDING: '未派发',
    IN_PROGRESS: '派发中',
    DISTRIBUTED: '已派发',
    CANCEL: '已取消',
    PRE_PROCESS: '派发中',
  },
  referFriendEvent: {
    INFO: '完善个人信息',
    FIRST: '首存',
    SECOND: '二存',
  },
  rollover: {
    recordStatus: {
      ONGOING: '进行中',
      COMPLETED: '完成',
      VOID: '作废',
      CANCEL: '手动取消',
    },
    rolloverEventType: {
      UPDATE: 'Update Rollover',
      CREATE: 'Create Rollover',
      BALANCE_CHECK: 'Balance Check',
    },
  },
  rebateLevel: {
    LEVEL_0: '等级 0',
    LEVEL_1: '等级 1',
    LEVEL_2: '等级 2',
    LEVEL_3: '等级 3',
    LEVEL_4: '等级 4',
    LEVEL_5: '等级 5',
    LEVEL_6: '等级 6',
    LEVEL_7: '等级 7',
    LEVEL_8: '等级 8',
    LEVEL_9: '等级 9',
    LEVEL_10: '等级 10',
    LEVEL_11: '等级 11',
    LEVEL_12: '等级 12',
    LEVEL_13: '等级 13',
  },
  gameQuiz: {
    questions: {
      1: '哪方获胜?',
      2: '让分局 (包括加时)',
      3: '总回合 (包括加时) 击杀总数',
      4: '两队指定回合局数/击杀',
      5: '击杀最高队伍?',
      6: '指定回合第一血',
    },
    answers: {
      CSGO: {
        handicapAbove: '两队相差 4.5 或以上',
        handicapBelow: '两队相差 4.5 或以下',
        killBig: '总回合大 21.5',
        killSmall: '总回合小 21.5',
        killSignal: '单',
        killDouble: '双',
      },
      LOL: {
        handicapAbove: '两队相差 4.5 或以上',
        handicapBelow: '两队相差 4.5 或以下',
        killBig: '总击杀大 25.5',
        killSmall: '总击杀小 25.5',
        killSignal: '单',
        killDouble: '双',
      },
      DOTA2: {
        handicapAbove: '两队相差 10.5 或以上',
        handicapBelow: '两队相差 10.5 或以下',
        killBig: '总击杀大 49.5',
        killSmall: '总击杀小 49.5',
        killSignal: '单',
        killDouble: '双',
      },
    },
  },
  promoLabel: {
    new: '最新',
    hot: '热门',
    normal: '正常',
    recommend: '推荐',
    daily: '日常',
    newbie: '新人',
    limit: '限时',
    featured: '精选',
  },
  messageType: {
    NOTIFICATION: '通知',
    ACTIVITY: '活动',
    ANNOUNCEMENT: '公告',
    MATCH: '赛事',
    PAYMENT: '充提',
  },
  sitePlatform: {
    followType: '类型',
    follow: '遵循之前',
    new: '更新游戏类型',
  },
  sortType: {
    ASC: '升序',
    DESC: '降序',
  },
  promoType: {
    WELCOME: '欢迎',
    SPORT: '体育',
    ESPORT: '电竞',
    FISH: '捕鱼',
    LIVECASINO: '真人',
    SLOTGAME: '电子',
    POKER: '棋牌',
    DAILY: '日常',
    FTD: '首存',
    LOTTERY: '彩票',
    OTHER: '其他',
    LIMITED: '限时热门',
    SLOTWELCOME: '电游 - 欢迎',
    SLOTDAILY: '电游 - 日常',
    SLOTOTHER: '电游 - 其他',
  },
  scheduleType: {
    NORMAL: '普通',
    BET_RECORDS: '抓取下注记录',
  },
  summonFailReason: {
    PRIVI_IS_CLOSED: '优惠已关闭',
    PRIVI_HAS_ENDED: '优惠已结束',
    PRIVI_HAVENT_START: '优惠未开始',
    SUMMONER_VIP_LEVEL_NOT_REACH: '唤醒人未达VIP要求',
    MEMBER_VIP_LEVEL_EXCEED_1: '被唤醒人超过VIP要求',
    MEMBER_HISTORY_DEPOSIT_AMOUNT_NOT_REACH: '被唤醒人未达历史存款要求',
    MEMBER_DEPOSIT_IN_PAST_15_DAYS: '被唤醒人有近期存款',
    MEMBER_BET_IN_PAST_15_DAYS: '被唤醒人有近期下注',
    MEMBER_AND_SUMMONER_SAME_REGISTER_DETAIL: '唤醒人和被唤醒人相同注册信息',
    MEMBER_ALREADY_SUMMONED: '会员已被唤醒',
  },
  lottery: {
    status: {
      BET: '下注',
      WIN: '赢',
      LOSS: '输',
    },
    prizeLevel: {
      FIRST_PRIZE: '直选',
      GROUP_THREE: '组三',
      GROUP_SIX: '组六',
      TWO_D: '2D',
    },
  },
  memberReferStatus: {
    PENDING: '核实中',
    SUCCESS: '合格',
    FAILED: '失败',
  },
  memberReferFailReason: {
    PRIVI_IS_CLOSED: '优惠已关闭',
    PRIVI_HAS_ENDED: '优惠已结束',
    VIP_LEVEL_IS_NOT_ALLOWED: 'VIP等级不允许',
    AFFILIATE_IS_NOT_ALLOWED: '代理不允许',
    ERROR_MEMBER_PHONE_NOT_VERIFIED: '手机未认证',
    ERROR_REGISTER_DUPLICATE_IP_OR_SID: '侦测到相同IP或设备',
    ERROR_DEPOSIT_AMT_MIN_NOT_MEET: '最低存款要求未达成',
    ERROR_BET_AMT_MIN_NOT_MEET: '最低下注要求未达成',
    ERROR_SIMILAR_IP_DETECTED: '侦测到相同IP',
    ERROR_SIMILAR_SID_DETECTED: '侦测到相同设备',
  },
  rewardType: {
    BET: '投注',
    DEPOSIT: '存款',
    ONE_TIME: '一次',
  },
  smsSend: {
    template1: '欢迎新用户',
    template2: '存送优惠',
    template3: '老用户回归',
  },
  displayLocation: {
    HOMEPAGE: '首页',
    PROMO_PAGE: '优惠页面',
    DEPOSIT_PAGE: '存款页面',
    WITHDRAW_PAGE: '提款页面',
    PERSONAL_CENTRE: '个人中心',
  },
  mode: {
    DYNAMIC: '页面浮动',
    STATIC: '直接显示',
  },
  autoplayStatus: {
    ON: '开',
    OFF: '关',
  },
  valueRulesType: {
    INPUT: '输入框',
    RADIO: '单选框',
    SELECT: '下拉框',
    SWITCH: '开关',
    CHECKBOX: '多选框',
  },
  withdrawType: {
    Manual: '手动',
    AUTO_WITHDRAW: '自动',
  },
  siteType: {
    main: '主站',
    slot: '电游',
  },
  language: {
    en: '英语',
    zh: '汉语',
    th: '泰语',
    kr: '韩语',
    vn: '越南语'
  },
  rewardStatus: {
    PENDING: '审核中',
    DISTRIBUTED: '已派发',
    REJECTED: '已拒绝',
  },
  treasure: {
    NORMAL: '正常宝箱',
    CS: 'CS宝箱',
    DRAGON: '龙宝箱'
  },
  fields: {
    account: '账号',
    accountHolder: '账号持有者',
    accountInfo: '账号信息',
    accountNumber: '账户号码',
    accountStatus: '账号状态',
    accurate: '精准查询',
    action: '操作',
    actionType: '操作类型',
    activeMember: '活跃会员',
    activeMemberCount: '活跃会员数',
    activePlayer: '活跃玩家',
    activeUser: '活跃会员',
    actualRate: '实际利息',
    add: '新增',
    addAds: '新增弹窗广告',
    addAdjustmentReason: '新增平账原因',
    addAffiliate: '新增代理',
    addAffiliateCommission: '新增代理分红',
    addAffiliateDomain: '新增代理域名',
    addAffiliateAnnouncement: '新增代理公告',
    addAffiliateAnnouncementType: '新增代理公告类型',
    addAffiliateFinancialConfig: '新增代理支付设置',
    addAnnouncement: '新增公告',
    addAnnouncementType: '新增公告类型',
    addBank: '新增银行',
    addBankCard: '新增银行卡',
    addBankToPayment: '新增银行至支付',
    addBankToWithdrawPlatform: '新增银行至提款平台',
    addBanner: '新增广告',
    addBbDacha: '新增BB别墅杯赛事',
    addBlacklist: '新增黑名单',
    addRewardGroup: '新增奖金组',
    addCurrency: '新增货币',
    addCurrencyRate: '新增汇率',
    addDepositSetting: '新增存款设置',
    addDomain: '新增域名',
    addEmail: '新增邮件',
    addFinancialLevel: '新增会员组别',
    addGame: '新增游戏',
    addGameMatch: '新增赛事',
    addGameQuiz: '新增竞猜',
    addGift: '新增奖品',
    addIpLabel: '新增 IP 标签',
    additionalTicketDetail: '加票信息',
    additionalKey: '其他信息',
    addJob: '新增任务',
    addLimit: '新增限制',
    addLotteryResult: '新增彩票结果',
    addMember: '新增会员',
    addMemberAmountAdjust: '新增会员平账',
    amountAdjustType: '平账类型',
    addNormalAmountAdjust: '普通平账',
    addCalculateAmountAdjust: '存送平账',
    addMemberPrivilegeBlacklist: '新增会员优惠黑名单',
    addMemberRebateRule: '新增会员特殊返水条例',
    addMenu: '新增菜单',
    addNodes: '新增节点',
    addPayType: '新增支付类型',
    addPlatform: '新增平台',
    addPlatformAccount: '新增平台账号',
    addPrivilegeInfo: '新增优惠信息',
    addPromoType: '新增优惠类型',
    addQuestionnaire: '新增问答',
    addRecipient: '新增收件人',
    addRedirect: '新增跳转页面',
    addRemark: '新增备注',
    addRiskLevel: '新增风险等级',
    addRole: '新增角色',
    addSettings: '新增配置',
    addSite: '新增站点',
    addSitePlatform: '新增平台站点',
    addStage: '新增关卡',
    addSystemMessage: '新增系统信息',
    addSyncDefault: '同步三天内的赛事',
    addTelephone: '新增手机号码',
    addTicket: '加票',
    addUser: '新增用户',
    addVip: '新增VIP',
    addVipRebateRule: '新增VIP返水条例',
    addWithdrawPlatform: '新增提款平台',
    addWithdrawSetting: '新增提款设置',
    addWithdrawalBankBlacklist: '新增提款银行卡黑名单',
    address: '地址',
    adjustAmount: '调整金额',
    adjustBetAmount: '调整投注金额',
    adjustBy: '平账操作者',
    adjustReason: '调整原因',
    adjustTime: '平账时间',
    adjustType: '平账类型',
    adjustmentReasonType: '平账原因类型',
    adjustmentReason: '平账原因',
    adjustment: '调整金额',
    adjustmentType: '调整类型',
    affiliate: '代理',
    affiliateBankCard: '代理银行卡',
    affiliateBankCardRecord: '代理银行卡记录',
    affiliateCode: '代理代码',
    channelName: '渠道名',
    affiliateDomainList: '代理域名列表',
    affiliateFinancialManagement: '代理支付管理',
    affiliateFBRequestList: '代理 Facebook 像素列表',
    affiliateFBRequestUrl: '代理域名',
    affiliateDepositChannel: '代理支付渠道',
    affiliateH5Link: 'H5版代理链接',
    affiliateInfo: '代理信息',
    affiliateLevel: '代理级别',
    affiliateLink: '代理链接',
    affiliateList: '代理列表',
    affiliateName: '代理名',
    affiliateShortUrlPlatform: '防封短链平台',
    affiliateStatus: '代理状态',
    affiliateTransferAmount: '代存金额',
    affiliateTransferRecord: '代理代存记录',
    affiliateWallet: '代存钱包',
    affiliateWebLink: 'Web版代理链接',
    advancedSearch: '高级搜索',
    afterAmount: '之后金额',
    afterBalance: '之后余额',
    afterEdit: '编辑后',
    afterVipLevel: '后VIP等级',
    alias: '别名',
    all: '全部',
    allBonus: '所有奖金显示',
    allvip: '全部VIP',
    allfinanciallevel: '全部组别',
    allprofit: '全部盈亏',
    allreviewstatus: '全部复核状态',
    allrisklevel: '全部风险等级',
    allmembers: '全部会员',
    allmembertype: '全部会员状态',
    amount: '金额',
    amountGreaterThan: '金额大于',
    amountLimitPerRain: '每场红包雨金额限制',
    adjust: '调整',
    announcement: '公告',
    announcementType: '公告类型',
    answer: '答案',
    answerOne: '题目一答案',
    answerTwo: '题目二答案',
    answerThree: '题目三答案',
    appVersion: 'App版本',
    applying: '申请中',
    approve: '批准',
    approveBy: '批准人',
    assigned: '已分配',
    assignment: '分配',
    attachment: '附件',
    auditTime: '审计时间',
    automaticPayment: '自动支付',
    autopay: '自动支付',
    autoplay: '自动播放',
    automaticPay: '自动支付',
    autoWithdrawSetting: '自动提款设置',
    autoWithdrawRule: '自动提款规则',
    awards: '奖池',
    awayTeam: '客队',
    awayTeamIcon: '客队图标',
    awayTeamResult: '客队得分',
    back: '返回',
    balance: '余额',
    balanceUrl: '余额地址',
    bank: '银行',
    bankAccount: '银行账号',
    bankCard: '银行卡',
    bankCardNumber: '银行卡号码',
    bankCode: '银行代码',
    bankName: '银行名',
    bankNumber: '银行号码',
    bankType: '银行类型',
    batchCancel: '批量取消',
    bbDachaDetails: 'BB别墅杯详情',
    bbdachaAnswers: 'BB别墅杯投票记录',
    bbdachaSetting: 'BB别墅杯设置',
    beanName: '对象名称',
    beforeAmount: '之前金额',
    beforeBalance: '之前余额',
    beforeEdit: '编辑前',
    beforeVipLevel: '前VIP等级',
    bet: '下注金额',
    betAmount: '投注金额',
    betId: '投注单号',
    belongType: '代理归属',
    betMoneyChange: '投注账变',
    betMoreThan: '投注金额大于',
    betRebateDetails: '投注返水记录明细',
    betRebateRecord: '投注返水记录',
    betRecords: '投注记录',
    betStatus: '投注状态',
    betTime: '投注时间',
    betTotal: '总下注金额',
    betWheelSettings: '投注转盘配置',
    betWheelRecords: '投注转盘记录',
    binded: '已绑定',
    birthday: '生日',
    bindTypeAll: '全部',
    bindTypeNew: '新增',
    bindTypeRemove: '解绑',
    blacklisted: '黑名单',
    bonus: '奖金',
    bonusAmount: '奖金金额',
    bonusDays: '奖金日',
    bonusReceived: '已领取奖金',
    bonusType: '奖金类型',
    byDepositDateDesc: '存款日期低到高',
    byDepositDateAsc: '存款日期高到低',
    byFinishDateDesc: '完成日期低到高',
    byFinishDateAsc: '完成日期高到低',
    byPaymentDateDesc: '支付日期低到高',
    byPaymentDateAsc: '支付日期高到低',
    byWithdrawDateDesc: '提款日期低到高',
    byWithdrawDateAsc: '提款日期高到低',
    byprofitasc: '负盈利低到高',
    byprofitdesc: '负盈利高到低',
    bydateasc: '日期高到低',
    bydatedesc: '日期低到高',
    bulkApprove: '批量批准',
    bulkWithdraw: '批量提款',
    callbackUrl: '回调地址',
    cancel: '取消',
    cancelAll: '全部取消',
    cancelBbDacha: '取消BB别墅杯赛事',
    cancelBySearch: '一键取消',
    cancelDeposit: '取消存款',
    cancelGameMatch: '取消赛事',
    cancelGift: '取消奖品兑换',
    cancelMatch: '取消赛事',
    cancelQuiz: '取消赛事问答',
    cancelRemark: '取消备注',
    cancelRolloverRecord: '取消流水记录',
    cancelAllRolloverRecord: '取消全部流水记录',
    cancelUpdate: '取消更新',
    cancelType: '取消类型',
    cardAccount: '银行户名',
    cardAddress: '开户行地址',
    cardNumber: '银行卡号',
    cardTime: '操作日期',
    cardTypeAll: '全部',
    cardTypeBank: '银行',
    cardTypeWallet: '电子钱包',
    cardTypeCrypto: '加密货币',
    category: '类别',
    cause: '原因',
    changeAffiliate: '更换代理',
    check: '审核中',
    checkall: '全选',
    checkBy: '审核人',
    checkDate: '审核日期',
    checkExclusiveUrl: '查看专属网址',
    checkFail: '审核失败',
    checkInRecord: '签到记录',
    checkTime: '审核时长',
    choice: '选项',
    choiceOne: '题目一选项',
    choiceTwo: '题目二选项',
    choiceThree: '题目三选项',
    claimRecord: '领取记录',
    claimTime: '领取时间',
    claimableRebate: '可领取返水金额',
    className: '类别名',
    clearingSum: '结算总和',
    clientType: '客户类型',
    close: '关闭',
    code: '代码',
    color: '颜色',
    commission: '分红',
    commissionBalance: '佣金余额',
    commissionModel: '分红模式',
    commissionPercent: '分红 %',
    commissionRate: '分红比率',
    commissionReport: '佣金明细',
    commissionWallet: '佣金钱包',
    componentName: '组件名称',
    componentPath: '组件路径',
    composeMessage: '撰写邮件',
    consumingTime: '耗费时间',
    commitTime: '订单日期',
    companyProfit: '公司盈利',
    configGroup: '设置组',
    confirm: '确认',
    confirmAndImport: '确认并导入',
    confirmAndSend: '确认并发送',
    confirmNewPassword: '确认新密码',
    confirmPassword: '确认密码',
    confirmPay: '确认结算',
    confirmStatus: '确认状态',
    confirmBy: '确认人',
    content: '内容',
    contentList: '符号列表',
    convertRate: '转化率',
    copy: '复制',
    copyPayment: '正在复制',
    copyTo: '复制到',
    copyToOtherLevel: '复制到其他会员组别',
    country: '国家',
    countryImage: '国家图像',
    createBy: '创建人',
    createTime: '创建时间',
    createVote: '创建投票',
    cronExpression: 'Cron表达式',
    currency: '货币',
    currencyCode: '货币代码',
    currencyName: '货币名称',
    currencyRate: '汇率',
    currencyWallet: '钱包',
    currentPassword: '当前密码',
    currentPlace: '当前位置',
    customerSupportAddress: '客服地址',
    dailyActiveMemberCount: '当日活跃会员数',
    dailyDeposit: '每日存款数量',
    dailyDepositAmount: '每日存款金额',
    dailyMin: '每日最低转盘次数',
    dailyMinRegLimit: '每日最小注册限制',
    dailyWithdraw: '每日提款数量',
    dailyWithdrawAmount: '每日提款金额',
    dailyFailAmount: '每日失败存款金额',
    dailyFailDeposit: '每日失败存款数量',
    dailyFailWithdraw: '每日失败提款数量',
    dailyFirstDepositLimit: '每日首次存款限额',
    dailyMinFirstDepositLimit: '每日最低首次存款限额',
    dailyMaxFirstDepositLimit: '每日最大首次存款限额',
    dailyFirstDepositProbability: '每日首次存款概率',
    dailyMax: '每日最高转盘次数',
    dailyMaxRegLimit: '每日最大注册限制',
    dailyRegProbabilty: '每日注册概率',
    dailyMemberFailDeposit: '每日用户失败存款数量',
    dailyMemberFailWithdraw: '每日用户失败提款数量',
    dailyRainDuration: '红包雨时间',
    dailyRefreshDuration: '红包雨计数时间',
    dailyRegLimit: '每日注册限制',
    dailySuccessAmount: '每日成功存款金额',
    dailySuccessDeposit: '每日成功存款数量',
    dailySuccessWithdraw: '每日成功提款数量',
    dailyMemberSuccessDeposit: '每日用户成功存款数量',
    dailyMemberSuccessWithdraw: '每日用户成功提款数量',
    dailySuccessPercentage: '每日成功存款比率',
    dailyTotalAmount: '每日总金额',
    dailyTotalRedeem: '每日总领取次数',
    dayWithdrawCount: '每日提款次数',
    day1: '第一天解锁',
    day2: '第二天解锁',
    day3: '第三天解锁',
    day4: '第四天解锁',
    day5: '第五天解锁',
    day6: '第六天解锁',
    date: '日期',
    data: '资料',
    dataBefore: '之前数据',
    dataAfter: '之后数据',
    dateReceived: '领取日期',
    dateType: '日期类型',
    deduct: '扣除',
    deductMemberAmountAdjust: '扣除会员平账',
    defaultBonus: '默认奖金',
    defaultFinancialLevel: '默认会员组别',
    defaultAgentFinancialLevel: '默认代理组别',
    defaultRiskLevel: '默认风险等级',
    defaultAgentRiskLevel: '默认代理风险等级',
    defaultVip: '默认VIP',
    defaultAgentVip: '默认代理VIP',
    delete: '删除',
    runAtOnce: '立即执行',
    deleteRole: '删除角色',
    deliver: '发送礼品',
    delivered: '已送达',
    deliverGift: '运送礼品',
    deposit: '存款',
    depositAmount: '存款金额',
    depositAverage: '人均充值',
    depositBettingAmount: '存款/投注',
    depositCount: '存款次数',
    depositDate: '存款日期',
    deposited: '已存款',
    depositExchangeRate: '存款兑换率',
    depositMemberCount: '存款人数',
    depositPerSpin: '每次转盘存款金额',
    depositSerialNo: '存款流水号',
    depositStatus: '存款状态',
    depositWithdrawalProfit: '存提利润',
    describe: '描述',
    description: '描述',
    desktopImage: '桌面图像',
    desktopImageDark: '桌面图像（深色）',
    desktopBackgroundImage: '桌面背景图像',
    desktopBackgroundImageDark: '桌面背景图像（深色）',
    desktopBanner: '桌面广告图',
    desktopBannerDark: '桌面广告图（深色）',
    details: '信息',
    device: '设备',
    dif: '存提差',
    disable: '禁用',
    disableAffiliate: '禁用代理',
    disableAll: '禁用所有',
    displayUrl: '展示地址',
    distributed: '已发放',
    distributeBy: '派发者',
    distributePrivilege: '发放奖金',
    distributeRebate: '发放返水',
    distributeRefer: '发放好友邀请奖金',
    distributeTime: '派发时间',
    domain: '域名',
    domainCount: '域名数量',
    domainList: '域名列表',
    done: '完成',
    doneBy: '操作账号',
    downgradeToApplying: '降级至申请中',
    downgradeToWaitPay: '降级至待支付',
    downlineAffiliate: '直属代理',
    downlineAffiliatePaymentChannel: '下级代理支付渠道',
    downlineCommission: '下级分红',
    downlineCommissionRate: '下级分红比率',
    downlineMember: '直属会员',
    downlineProfit: '下级盈利',
    downloadTemplate: '下载模板',
    draw: '平局',
    edit: '编辑',
    editAds: '编辑弹窗广告',
    editAdjustmentReason: '编辑平账原因',
    editAffiliateCommission: '编辑代理分红',
    editAffiliateDomain: '编辑代理域名',
    editAffiliateAnnouncement: '编辑代理公告',
    editAffiliateAnnouncementType: '编辑代理公告类型',
    editAffiliateFinancialConfig: '编辑代理支付设置',
    editAmount: '编辑金额',
    editAnnouncement: '编辑公告',
    editAnnouncementType: '编辑公告类型',
    editBank: '编辑银行',
    editBankCard: '编辑银行卡',
    editBanner: '编辑广告',
    editBelongType: '编辑网页类型',
    editBlacklist: '编辑黑名单',
    editCheck: '更改',
    editConfig: '编辑设置',
    editCurrency: '编辑货币',
    editCurrencyRate: '编辑汇率',
    editDepositSetting: '编辑存款设置',
    editDetails: '编辑信息',
    editEmail: '编辑邮件',
    editFinancialLevel: '编辑会员组别',
    editGame: '编辑游戏',
    editGameMatch: '编辑赛事',
    editGift: '编辑奖品',
    editIpLabel: '编辑 IP 标签',
    editJob: '编辑任务',
    editMemberRebateRule: '编辑会员特殊返水条例',
    editMenu: '编辑菜单',
    editPayType: '编辑支付类型',
    editPlatform: '编辑平台',
    editPlatformAccount: '编辑平台账号',
    editPrivilegeInfo: '编辑优惠信息',
    editPromoType: '编辑优惠类型',
    editQuestionnaire: '编辑问答',
    editRedirect: '编辑跳转页面',
    editRemark: '编辑备注',
    editRewardGroup: '编辑奖金组',
    editRiskLevel: '编辑风险等级',
    editRole: '编辑角色',
    editScore: '编辑得分',
    editSettings: '编辑配置',
    editSite: '编辑站点',
    editSitePlatform: '编辑平台站点',
    editStage: '编辑关卡',
    editTelephone: '编辑手机号码',
    editTimeType: '编辑时间类型',
    editType: '编辑类型',
    editUser: '编辑用户',
    editVip: '编辑VIP',
    editVipRebateRule: '编辑VIP返水条例',
    editWithdrawPlatform: '编辑提款平台',
    editWithdrawSetting: '编辑提款设置',
    email: '邮箱',
    endBbDacha: '结束BB别墅杯赛事',
    endDate: '结束日期',
    endMatch: '结束赛事',
    endQuiz: '结束赛事问答',
    endTime: '结束时间',
    estimateRate: '预估利息',
    event: '活动',
    exclusiveUrlWeb: '专属网址（网页版）',
    exclusiveUrlWap: '专属网址（手机版）',
    exclusiveUrlApp: '专属后台网址',
    exclusiveUrlCashier: '专属后台网址',
    exclusiveUrl: '专属网址',
    exchangeRate: '兑换率',
    exportToExcel: '导出至Excel',
    expressCompany: '快递公司',
    externalUrl: '外部网址',
    extraParam: '扩展信息',
    fail: '失败',
    failReason: '失败原因',
    fbid: 'Facebook 像素ID',
    fbToken: 'Facebook 像素令牌',
    fdConvertRate: '存款转化率',
    features: '特性',
    fee: '手续费',
    feedbackType: '催单类型',
    feedbackTypeAll: '全部',
    feedbackTypeDeposit: '存款',
    feedbackTypeWithdraw: '提款',
    feedbackStatus: '催单状态',
    feedbackStatusAll: '全部',
    feedbackStatusPending: '核实中',
    feedbackStatusSuccess: '已核实',
    feedbackTime: '催单日期',
    feeRate: '收费率',
    subtractAmount: '扣费金额',
    field: '数据名称',
    finalSum: '最终佣金',
    financialLevel: '会员组别',
    financePhotos: '图片',
    financeType: '存/提',
    financeRemark: '财务备注',
    financeStatus: '状态',
    finishDate: '完成日期',
    firstDeposit: '首存用户',
    firstTicket: '第一张票 (下注/存款)',
    firstDepositMemberCount: '首存人数',
    firstDepositMember: '首存用户',
    freeze: '冻结',
    freezeMember: '冻结会员',
    unfreezeMember: '解冻会员',
    freezeType: '冻结类型',
    frequency: '频率',
    fromWallet: '从主钱包调整',
    fromCommission: '从佣金调整',
    ftd: '首存',
    ftdAmount: '首存金额',
    ftdAverage: '人均首存',
    ftdCount: '首存人数',
    ftdDownlineCount: '下线首存人数',
    depositDownlineCount: '下线存款人数',
    regDownlineCount: '下线注册人数',
    ftdTime: '首存时间',
    ftdTxn: '首存交易',
    fundingInfo: '资金信息',
    game: '游戏',
    gameAccountName: '游戏帐户名',
    gameCode: '游戏代码',
    domainEasy: 'Domain 易记域名',
    videoShiPin: 'Video 视频',
    CHARITY: "慈善 Url",
    gameConsolidateReport: '游戏报表',
    gameMatch: '保险投注赛事',
    gameMatchRecord: '玩家保险投注记录',
    gameName: '游戏名称',
    gameQuiz: '竞猜设置',
    gameQuizAnswer: '玩家竞猜记录',
    gameStepsRecords: '闯关记录',
    gameStepsSettings: '闯关配置',
    gameType: '游戏类型',
    generateSummary: '生成汇总',
    goalAgainst: '失球',
    goalDifference: '净胜球',
    goalScored: '得球',
    groupId: '消息消费组',
    gift: '奖品',
    giftCode: '奖品代码',
    giftName: '奖品名称',
    giftRecord: '奖品兑换记录',
    hasPromo: '有优惠',
    hidden: '隐藏',
    homeTeam: '主队',
    homeTeamIcon: '主队图标',
    homeTeamResult: '主队得分',
    icon: '图标',
    pIcon: '优惠图标',
    id: 'ID',
    identifyCode: '识别码',
    imageSize: '图像尺寸',
    import: '导入',
    inbox: '收件箱',
    ineligible: '不符合',
    innerSetting: '内圈几率设置',
    ip: '访问 IP',
    isDeposit: '显示存款会员',
    isMultiple: '支持多选项',
    itsYou: '是你',
    job: '任務',
    jobLog: '日志列表',
    jobName: '任务名称',
    jobType: '任务类型',
    keysAcquired: '获取钥匙',
    keyRecord: '获取钥匙记录',
    keyUsed: '已使用钥匙',
    label: '标签',
    labelCancel: '取消',
    language: '语言',
    lastDepositTime: '最后存款时间',
    lastDigit: '尾数',
    lastDigitMinDayDeposit: '尾数红包当日最小存款',
    lastDigitRules: '尾数红包规则',
    lastLogin: '最近登录',
    lastLoginAddress: '最近登录地址',
    lastLoginIp: '最近登录IP',
    lastLoginTime: '最近登录时间',
    lastMonth: '上月',
    last3Months: '上3个月',
    last6Months: '上6个月',
    last5Min: '最近5分钟',
    last15Min: '最近15分钟',
    last30Min: '最近30分钟',
    last1Hour: '最近1小时',
    lastWeek: '上周',
    latestFetchBetRecordTime: '最近抓取投注记录时间',
    level: '级别',
    levelUpCredit: '升级金额',
    limitNumber: '限量',
    localCurrencyAmount: '当地货币金额',
    lockStatus: '锁定状态',
    login: '登录',
    loginAddress: '登录地址',
    loginFailedReason: '登录失败原因',
    loginInfo: '登录信息',
    loginIp: '登录IP',
    loginName: '登录名',
    loginNameSeparateComma: '登录名（以逗号来区分）',
    loginStatus: '登录状态',
    loginTime: '登录时间',
    loginUrl: '登录网址',
    loginWay: '登录方式',
    logoutPlayer: '登出用户',
    loss: '输',
    lossAmount: '亏损金额',
    lossRebateRecord: '亏损返水记录',
    lotteryRecordList: '彩票记录',
    lotteryResultList: '彩票结果',
    lowBalance: '最低余额',
    maintenanceEndTime: '维护结束时间',
    maintenanceStartTime: '维护开始时间',
    maintenanceTime: '维护时间',
    mallCode: '商户号',
    mallExtraKey: '扩展秘钥',
    mallKey: '秘钥',
    mallName: '支付编码',
    manualPay: '手动支付',
    massImport: '批量导入',
    messageAffiliateDepositDisplay: '请设置新代理支付显示',
    matchTime: '赛事时间',
    matchTitle: '赛事标题',
    matureTime: '满期时间',
    maxBonus: '最高奖金',
    maxDailyWithdraw: '每日最高提款金额',
    maxDailyWithdrawTimes: '每日最多提款次数',
    maxDeposit: '最高存款',
    maxMemberClaimCountPerRain: '每场红包雨玩家最多领取次数',
    maxMemberClaimCountPerDay: '每日玩家最多领取次数',
    maxWithdrawAmount: '最高提款金额',
    mediaDesc: '媒体名称',
    mediaUrl: '媒体地址',
    member: '会员',
    memberBankCard: '会员银行卡',
    memberBankCardRecord: '会员银行卡记录',
    memberBetRecord: '会员投注记录',
    memberBetRecordByPlatform: '平台下注汇总',
    memberCommission: '会员分红',
    memberConsolidateReport: '会员报表总览',
    memberDetailDeposit: '总充值金额',
    memberDetailDepositCount: '充值次数',
    memberDetailPrivilege: '总优惠额',
    memberDetailWithdraw: '总提款额',
    memberFeedbackType: '反馈类型',
    memberId: '会员ID',
    memberInfo: '会员信息',
    memberList: '会员列表',
    memberMessage: '发件箱',
    membersDailyTotalDeposit: '会员当日总存款',
    membersMonthlyTotalDeposit: '会员当月总存款',
    memberName: '会员名',
    memberPlatformAction: '操作（手动注册/删除）',
    memberPlatformUpdate: '同步密码',
    memberPrivilegeBlacklist: '会员优惠黑名单',
    memberProfit: '代理盈利',
    memberProfitDownlineProfitUnsettleCommission: '代理盈利/下线盈利/上月结余',
    memberPrivilegeRecord: '会员优惠记录',
    memberRemark: '玩家备注',
    memberReport: '会员报表',
    memberType: '会员类型',
    memberWithdrawLog: '会员提款日志',
    memberRolloverEvent: '流水日志',
    menuGame: '菜单游戏序列',
    menuIcon: '菜单图标',
    menuTitle: '菜单标题',
    menuType: '菜单类型',
    menuOnlineUser: '在线人数',
    message: '信息',
    min: '最低盈亏金额',
    minAmount: '最低金额',
    max: '最高盈亏金额',
    maxAmount: '最高金额',
    maxTicket: '最高可获得票',
    minno: '请输入最低金额',
    maxno: '请输入最高金额',
    minActiveMemberCount: '最低活跃会员数',
    minBalance: '最低余额',
    minBonus: '最低奖金',
    minDeposit: '最低存款',
    minTotalCommission: '最低总分红',
    minWithdrawAmount: '最低提款金额',
    mobile: '手机版',
    mobileImage: '手机图像',
    mobileImageDark: '手机图像（深色）',
    mobileBackgroundImage: '手机背景图像',
    mobileBackgroundImageDark: '手机背景图像（深色）',
    mobileBanner: '手机广告图',
    mobileBannerDark: '手机广告图（深色）',
    mobileMessage: '手机提示信息',
    mobileWay: '移动端',
    mode: '模式',
    moneyChange: '账变',
    month: '月份',
    monthlyDepositAmount: '当月存款金额',
    monthlyDepositCount: '当月存款次数',
    mqLogProcessWithTitle: '标题 (SummaryDoris)',
    mqLogProcessTopic: '主题',
    mqLogProcessTimestamp: '系统时间',
    mqLogTimestampWithTimezone: '站点时间',
    name: '名称',
    nbaMatchPreseasonRecord: 'NBA季前赛记录',
    nbaMatchRecord: 'NBA季后赛记录',
    nbaMatchPreseasonSetting: 'NBA季前赛配置',
    nbaMatchSetting: 'NBA季后赛配置',
    needRegister: '需要注册',
    needSpecify: '需要自填',
    netProfit: '净利润',
    netPhone: '呼叫设置',
    newMember: '新会员',
    newMemberCount: '新会员数',
    newPassword: '新密码',
    nextActivationTime: '下次启动时间',
    nextGetBetEndTime: '下次取投注记录结束时间',
    nextGetBetIndex: '下次取投注记录指数',
    nextGetBetPage: '下次取投注记录页面',
    nextGetBetStartTime: '下次取投注记录开始时间',
    nextLevel: '晋级',
    nextLevelPoint: '晋级点数',
    no: '否',
    noData: '查无数据',
    noDeposit: '无存款',
    noLimit: '无限制',
    noOfDeduction: '扣除总数',
    noOfDepositTimes: '存款总数',
    noOfPrivilegeClaimed: '已领取优惠总数',
    noOfReimbursement: '报销总数',
    noOfWinners: '得奖人数',
    noOfWithdrawTimes: '提款总数',
    notBinded: '未绑定',
    notice: '注意',
    notRead: '未读',
    notReplied: '未回复',
    notRegister: '未注册',
    number: '数字',
    oneTimeSpin: '单次转盘',
    onlyDeposit: '只显示存款会员',
    onlyFake: '只显示非玩家资料',
    onlyReal: '只显示玩家资料',
    open: '开启',
    operate: '操作',
    operator: '操作人',
    operateTime: '操作时间',
    operationTime: '操作时间',
    operationType: '操作类型',
    orderBy: '排序字段',
    orderNo: '订单号',
    orderTrackingNo: '订单号',
    outerSetting: '外圈几率设置',
    param: '参数',
    parentSite: '上级站点',
    password: '密码',
    pause: '暂停',
    pay: '支付',
    payBy: '支付人',
    payMessage: '支付信息',
    payMessageMobile: '手机支付信息',
    payResultType: '支付结果显示方式',
    payTime: '支付时长',
    payType: '支付类型',
    payTypeCode: '支付代码',
    payTypeName: '支付名称',
    payment: '支付',
    paymentArea: '支付区',
    paymentBy: '支付人',
    paymentCard: '支付卡',
    paymentChannel: '支付渠道',
    paymentDate: '支付日期',
    paymentDisplay: '支付显示',
    paymentFee: '支付手续费',
    paymentMethod: '支付方式',
    paymentName: '支付名称',
    paymentOnGoing: '支付中',
    paymentType: '支付类型',
    payout: '派彩',
    payoutTotal: '总派彩',
    return_ratio: '返奖率',
    return_ratio_total: '总返奖率',
    pcWay: 'PC端',
    pending: '中止',
    pendingTransaction: '待处理',
    permission: '权限',
    permissionAssignment: '分配权限',
    pgroup: '优惠组别',
    photo: '图片',
    piggyBankRecords: '存钱罐记录',
    piggyBankSettings: '存钱罐设置',
    place: '位置',
    placeTime: '存入时间',
    planDays: '投资期限(天)',
    planOdds: '利率',
    planLimitMin: '投资最小限额',
    planLimitMax: '投资最大限额',
    planTimeLimit: '限购次数(0为不限)',
    platform: '平台',
    platformDetails: '平台详情',
    platformAccount: '平台账号',
    platformId: '平台 ID',
    platformBalance: '平台余额',
    platformCode: '平台代码',
    platformFee: '场馆费',
    platformFundTransfer: '平台转账',
    platformMatchId: '平台赛事ID',
    platformName: '平台名',
    playerName: '玩家名',
    pleaseChoose: '请选择',
    pleaseInput: '请输入',
    points: '总积分',
    poolAmount: '奖池',
    previousLevel: '上级',
    previousLevelName: '上级名称',
    previousPlace: '之前位置',
    priority: '优先值',
    privilege: '优惠',
    privilegeCode: '优惠代码',
    privilegeId: '优惠 ID',
    privilegeName: '优惠名',
    privilegeRedeemed: '优惠已领取',
    privilegeSerialNo: '优惠流水号',
    privilegeType: '优惠类型',
    probability: '中奖概率',
    profit: '利润',
    profitpositive: '正数',
    profitnegative: '负数',
    promo: '优惠',
    promoApplication: '优惠申请',
    promoCode: '优惠代码',
    promoPages: '优惠页面',
    promoStyle: '优惠方式',
    promoType: '优惠类型',
    queryNumber: '搜索数量',
    queryOption: '搜索选项',
    queryRestriction: '搜索限制',
    question: '题目',
    questionOne: '题目一',
    questionTwo: '题目二',
    questionThree: '题目三',
    quizDetails: '赛事问答详情',
    quizTime: '问答时间',
    quizTitle: '赛事问答标题',
    randomMember: '随机玩家',
    rate: '比率',
    realName: '真实姓名',
    englishName: '英文姓名',
    realVirtualVotes: '真实+虚拟投票',
    reason: '原因',
    reasonType: '原因类型',
    reasonTemplate: '原因模板',
    rebate: '返水',
    rebateDistributeTime: '返水发放时间',
    rebateLevel: '返水等级',
    rebatePercentage: '返水比例 %',
    regEndDate: '注册结束时间',
    regStartDate: '注册开始时间',
    regTime: '注册时间',
    redPacketMinDayDeposit: '红包雨当日最小存款',
    redPacketMinTotalDeposit: '红包雨历史最小存款',
    redPacketMinTotalDepositDays: '历史存款有效天数',
    redPacketMinTotalDepositWeeks: '历史存款有效自然周数',
    redPacketMinDayBetAmount: '红包雨当日最低投注金额',
    rule: '规则',
    maxRebate: '最大返水额',
    maxBalance: '最高余额',
    nickName: '昵称',
    rebateAmount: '返水金额',
    receiveTime: '领取时间',
    receiveType: '领取模式',
    recipient: '收件人',
    recipientTelephone: '收件人电话',
    records: '记录',
    recordDate: '记录日期',
    recordTime: '记录时间',
    redeemCash: '奖品折现',
    redeemedBy: '领取人',
    redeemPoints: '兑换分数',
    redirect: '域名转址',
    reenterPassword: '重新输入密码',
    referredName: '被推荐人',
    referrer: '推荐人',
    referrerCode: '推荐人代码',
    referrerList: '推荐列表',
    referFriendRebateDetails: '邀请好友投注返水明细',
    referFriendRebateRecords: '邀请好友投注返水记录',
    registered: '已注册',
    register: '新注册用户',
    registerAddress: '注册地址',
    registerCount: '新注册用户人数',
    registerDevice: '注册装置',
    registerHost: '注册域名',
    registerIp: '注册IP',
    registerMember: '新注册用户',
    registerTime: '注册时间',
    registrationDate: '注册日期',
    referredFriends: '已推荐好友',
    referFriendRecord: '好友邀请记录',
    remark: '备注',
    remove: '删除',
    reply: '回复',
    replyTitle: '回复标题',
    replyContent: '回复内容',
    requestUrl: '请求地址',
    requestParam: '请求参数',
    responseBody: '返回参数',
    resend: '重发',
    reset: '重置',
    resettleBbDacha: '重新结算BB别墅杯赛事',
    resettleGameQuiz: '重新结算竞猜',
    resettleMatch: '重新结算赛事',
    result: '结果',
    resultDate: '开彩日期',
    resultNumber: '开彩号码',
    resultTime: '开彩时间',
    resume: '繼續申請中',
    revenueShare: '收入',
    review: '复查',
    reviewby: '操作人',
    reviewno: '未复核',
    reviewsuccess: '正确',
    reviewstatus: '复核状态',
    reviewfail: '错误',
    reviewRule: '审核规则',
    rewardGroup: '奖金组',
    rewardGroupDownlineQuota: '最高下线人数',
    rewardGroupName: '奖金组名称',
    riskControl: '风险管理',
    riskDepositLimit: '风控存款金额限制',
    riskLevel: '风险等级',
    riskPaymentChannel: '风控支付渠道',
    role: '角色',
    roleList: '角色列表',
    roleName: '角色名称',
    rollover: '流水倍数',
    rollOverAmt: '流水金额',
    rollOverMulti: '流水倍数',
    rolloverType: '流水类型',
    rolloverAmount: '所需流水金额',
    rolloverDetails: '流水详细描述',
    rolloverRecord: '流水记录',
    gameTypeRollover: '游戏类型流水',
    recommend: '推荐',
    round: '免费局数',
    router: '路由',
    rules: '彩金配置',
    ruleParam: '固定金额数组',
    ruleType: '规则类型',
    run: '启动',
    save: '储存',
    saveAsNewPayment: '另存为新支付',
    scheduleTime: '运行时间',
    schemaName: '模式名',
    score: '积分',
    search: '搜索',
    searchCondition: '搜索条件',
    securityQuestion: '安全密保',
    secondTicket: '第二章票(下注/存款)',
    selectedMembers: '指定会员',
    sendTime: '发送时间',
    sendSuccess: '发送成功',
    sendFail: '发送失败',
    select: '选择',
    selectNodeAddTo: '选择节点添加至',
    selected: '已选择',
    selectedNumber: '已选号码',
    selectedTeam: '竞猜队伍',
    send: '发送',
    separateByComma: '以逗号来区分',
    sequence: '排序',
    serialNo: '平台订单号',
    settle: '结算',
    settleAmount: '结算金额',
    settleAllPending: '结算所有待审核',
    settleGameMatch: '结算赛事',
    settleLastDayGameMatch: '结算昨日赛事',
    settlement: '结算',
    settleTime: '结算时间',
    settleView: '查看',
    settlePay: '发放',
    settleEdit: '调整',
    shareRatio: '佣金比例',
    show: '显示',
    showAll: '显示所有',
    sid: 'SID',
    site: '站点',
    siteCode: '站点代码',
    siteList: '站点列表',
    siteName: '站点名称',
    siteType: '站点类型',
    sn: '序',
    smsPhone: '电话号码',
    smsContent: '内容',
    smsStatus: '发送状态',
    smsSendTime: '发送时间',
    smsType: '短信类型',
    sorting: '排序',
    sortType: '排序类型',
    sourceType: '来源类型',
    sportBetResult: '体育投注结果',
    sportMatchRecord: '中超角逐记录',
    sportMatchSetting: '中超角逐配置',
    stage: '关卡',
    state: '状态',
    startCopy: '开始复制',
    startMatch: '开始赛事',
    startUpdate: '开始更新',
    startDate: '开始日期',
    startTime: '开始时间',
    status: '状态',
    steps: '步数',
    stepBonus: '奖金',
    stopAfterFailure: '失败后停止',
    subject: '标题',
    subSiteCount: '子站点数量',
    subtotal: '小计',
    success: '成功',
    successRate: '笔数成功率',
    successAmounntRate: '金额成功率',
    successfullyExport: '成功导出',
    summaryAdjustment: '账户调整',
    summaryBonus: '红利',
    summaryCompanyWinLoss: '公司输赢',
    summaryProfit: '公司盈利',
    summaryReport: '总结报表',
    summaryTable: '汇总表',
    summaryTotalBet: '投注人数',
    superiorAffiliateInfo: '上级代理信息',
    superiorAffiliateName: '上级代理名',
    superiorAffiliateCode: '上级代理代码',
    superiorAffiliateLevel: '上级代理级别',
    superiorCategory: '上级组件',
    superiorAffiliatePaymentChannel: '上级代理支付渠道',
    supplement: '补单',
    supplementDeposit: '存款补单',
    supportType: '支持类型',
    sureWin: '必中奖',
    sureWinBonus: '必中奖金',
    sureWinMinSpin: '必中奖最低转盘次数',
    suspend: '中止',
    switch: '转换',
    sync: '同步',
    switchPayment: '支付转换',
    systemPayment: '系统支付',
    szCnyControl: '数字人民币提款限制',
    targetType: '操作目标类型',
    targetId: '操作编号',
    targetName: '操作名称',
    teamA: 'A组',
    teamB: 'B组',
    teamC: 'C组',
    teamD: 'D组',
    teamE: 'E组',
    teamF: 'F组',
    team16: '16强',
    team8: '8强',
    team4: '4强',
    team2: '冠军赛',
    teamGroup: '队伍组别',
    teamName: '队伍名称',
    teamNameEn: '团队英文名称',
    teamNameLocal: '团队当地名称',
    teamOne: '团队一',
    teamOneIcon: '团队一图标',
    teamOneResult: '团队一得分',
    teamTwo: '团队二',
    teamTwoIcon: '团队二图标',
    teamTwoResult: '团队二得分',
    teamBackgroundImage: '团队背景图片',
    teamBackgroundImageDark: '团队暗色背景图片',
    telephone: '电话号码',
    telegramUsername: 'Telegram用户名',
    thirtyDaysdw: '30天存-提',
    thirdParty: '第三方',
    thirdPartyName: '第三方名称',
    thirdPartyTotalBet: '第三方游戏总投注',
    thirdPartyTotalPayout: '第三方游戏总中奖',
    thirdPartyTotalWin: '第三方游戏总盈利',
    thirdSerialNo: '三方支付订单号',
    thirdTicket: '第三章票(下注/存款)',
    thisMonth: '本月',
    thisWeek: '本周',
    ticketSetting: '票数设置',
    ticketNo: '票数号',
    tips: '提示',
    title: '标题',
    toApplying: '至申请中',
    toBeforePaid: '至待支付',
    toBePaid: '待支付',
    toChecking: '至待审核',
    today: '今日',
    toPay: '至待结算',
    toPaymentOnGoing: '至支付中',
    topic: '消息主题',
    toreview: '正在复查',
    toSuspend: '至暂不处理',
    total: '总计',
    totalActiveMembers: '总活跃会员数',
    totalActualRate: '总利息金额',
    totalAdjustment: '总调整金额',
    totalBalance: '总余额',
    totalBet: '总投注',
    totalBetAmount: '总投注额',
    totalBetCount: '投注笔数',
    totalBetMemberCount: '总下注人数',
    totalBetRecords: '总投注记录',
    totalBonus: '总奖金',
    totalCommission: '总分红',
    totalCommissionProfit: '佣金',
    totalCompanyProfit: '会员实际盈利',
    totalDeductAmount: '总扣除金额',
    totalDeposit: '总存款',
    totalDepositAmount: '存款总额',
    totalDepositCount: '总存款次数',
    totalDepositMemberCount: '总充值人数',
    totalWithdrawMemberCount: '总提款人数',
    totalDepositedAmount: '存款总额',
    totalDepositNumber: '总充值会员数',
    totalDownlineAffiliates: '总直属代理',
    totalDownlineMembers: '总直属会员',
    totalFailDeposit: '存款失败数量',
    totalFailDepositAmount: '存款失败总额',
    totalFailWithdraw: '提款失败数量',
    totalFailWithdrawAmount: '提款失败总额',
    totalFirstDeposit: '总首存用户',
    totalMemberDepositAmount: '总充值金额',
    totalMemberDepositCount: '总充值人数',
    totalNoOfDeposits: '存款总数',
    totalNoOfWithdrawals: '提款总数',
    totalPayout: '总派彩',
    totalPlaceAmount: '总存入金额',
    totalPlaceMemberCount: '总人数',
    totalPromo: '总优惠',
    totalProfit: '总利润',
    totalPrivilegeAmount: '总优惠金额',
    totalPrivilegeClaimAmount: '总优惠领取金额',
    totalRebateAmount: '总返水金额',
    totalRedeem: '总领取人数',
    totalRegister: '总新注册用户',
    totalRegisterCount: '注册会员总数',
    totalReimburseAmount: '总报销金额',
    totalSuccessDeposit: '存款成功数量',
    totalSuccessDepositAmount: '存款成功总额',
    totalSuccessDepositPercentage: '成功存款比率',
    totalSuccessWithdraw: '提款成功数量',
    totalSuccessWithdrawAmount: '提款成功总额',
    totalTime: '总时长',
    totalTransferIn: '总转入',
    totalTransferOut: '总转出',
    toUnderReview: '至审核中',
    totalValidBet: '总有效投注',
    totalWithdraw: '总提款',
    totalWithdrawAmount: '提款总额',
    totalWithdrawCount: '总提款次数',
    totalWithdrawnAmount: '提款总额',
    transaction: '转账信息',
    transactionStatus: '注单状态',
    transactionTime: '交易时间',
    transactionId: '注单号',
    transferDate: '转账日期',
    transferId: '转账ID',
    transferIn: '转入',
    transferOut: '转出',
    transferTime: '转账时间',
    transferType: '转账类型',
    triggerType: '触发类型',
    treasure: '宝箱',
    turnoverMultiple: '流水倍数',
    txnId: '交易ID',
    txnTime: '交易时间',
    type: '类型',
    uefaMatchRecord: '欧洲杯赛事记录',
    uefaMatchSetting: '欧洲杯赛事设置',
    uefaSettlement: '欧洲杯结算记录',
    uefaTeamRecord: '欧洲杯队伍记录',
    uefaTeamSetting: '欧洲杯队伍设置',
    undefined: '未定义',
    underMaintenance: '维护中',
    underReview: '审核中',
    unlockMember: '解锁账号',
    unlockSetting: '解锁设置',
    unlockUser: '解锁用户',
    unsettleCommission: '上月结余',
    url: '访问 URL',
    update: '更新',
    updateAlias: '更新别名',
    updateAwards: '更新奖池',
    updateBy: '更新人',
    updateCommissionModel: '更新分红模式',
    updateCommissionRate: '更新分红比率',
    updateFinancialLevel: '更新会员组别',
    updateMaintenanceTime: '更新维护时间',
    updatePassword: '更新密码',
    updatePlatformFee: '更新场馆费',
    updatePaymentFee: '更新支付手续费',
    updateRiskLevel: '更新风险等级',
    updateStatus: '更新状态',
    updateTime: '更新时间',
    updateVIPLevel: '更新VIP等级',
    upgradeToCheck: '升级至审核中',
    upgradeToPaymentOnGoing: '升级至支付中',
    upLineLoginName: '上级用户名',
    upload: '上传',
    upperName: '所属上级',
    usdtControl: 'USDT提款限制',
    usdtDepositAmount: '数字货币存款额',
    usdtDepositCount: '数字货币存款次数',
    username: '用户名',
    userType: '用户类型',
    validate: '验证',
    validity: '到期日',
    validBet: '有效投注额',
    validBetPerSpin: '每次转盘所需有效投注',
    value: '值',
    vcallName: '座机号',
    view: '查看',
    viewChoice: '查看问答选项',
    viewDetails: '查看详情',
    viewLog: '查看日志',
    viewLoginName: '查看下线用户名',
    vipLevel: 'VIP等级',
    vipRebateDetails: 'VIP返水记录明细',
    vipRebateRecord: 'VIP返水记录',
    vipRules: 'VIP红包规则',
    vipUpgradeList: 'VIP升级记录',
    vipWheelSettings: 'VIP转盘配置',
    vipWheelRecords: 'VIP转盘记录',
    virtualVotes: '虚拟投票',
    visitNumber: '访问量',
    votes: '票数',
    voteTime: '投票时间',
    walletType: '钱包类型',
    way: '设备',
    web: '电脑版',
    webMessage: '网页提示信息',
    win: '赢',
    winRate: '胜率',
    winnerTeam: '获胜队伍',
    withdraw: '提款',
    withdrawableBalance: '可提余额',
    withdrawArea: '提款区域',
    withdrawChannel: '提款渠道',
    withdrawExchangeRate: '提款兑换率',
    withdrawalFailureCause: '提款失败原因',
    withdrawalFailureType: '提款失败类型',
    withdrawAmount: '提款金额',
    withdrawCard: '提款卡',
    withdrawCode: '提款平台代码',
    withdrawCount: '提款次数',
    withdrawDate: '提款日期',
    withdrawMemberCount: '提款人数',
    withdrawName: '提款平台名称',
    withdrawPayoutBonus: '提款/派彩/奖金/调整',
    withdrawPlatform: '提款平台',
    withdrawPlatformList: '提款平台列表',
    withdrawPlatformName: '提款平台名称',
    withdrawReviewType: '提款审核类型',
    withdrawStatus: '提款状态',
    withdrawType: '提款类型',
    yes: '是',
    yesterday: '昨日',
    tigerCardType: '卡片类型',
    tigerCardOpeType: '操作方式',
    tigerCardSource: '虎卡来源/去向',
    tigerCardBeforeCount: '操作前卡片数量',
    tigerCardRealCount: '真实大奖卡数量',
    tigerCardVirtualCount: '虚拟大奖卡数量',
    tigerCardPeriod: '期数',
    tigerCardTime: '抽卡时间',
    tigerCardBonusTime: '发奖时间',
    timeType: '时间类型',
    count: '数量',
    host: '域名',
    port: '端口',
    user: '用户',
    from: '从',
    addEmailSetting: '新增邮件设置',
    editEmailSetting: '编辑邮件设置',
    signName: '公司名字',
    secretId: '密码',
    secretKey: '密钥',
    setUndefined: '设置未定义',
    appId: '应用ID',
    templateId: '模板ID',
    addSmsSetting: '新增短信设置',
    editSmsSetting: '编辑短信设置',
    requestExportToExcel: '请求导出至Excel',
    downloadManagerRecord: '下载中心记录',
    downloadExportedExcel: '下载已导出的Excel',
    redPacketAmountAfterReachingLimit: '红包达到上限后金额',
    requestBy: '请求者',
    requestTime: '请求时间',
    progress: '进度',
    download: '下载',
    updateProgress: '更新进度',
    add_new: '新建',
    date_added: '已添加时间段。',
    please_add_at_least_one_date_time: '请至少添加1个日期和1个时间。',
    multiple_rain_added: '已添加多个红包雨时间段。',
    date_already_exists: '日期已存在。',
    time_already_exists: '时间范围已存在。',
    add_single_daterange: '添加单个时间段',
    adde_multiple_daterange: '添加多个时间段',
    add_single_date_steps: '步骤：选择日期和时间范围，然后点击“添加”按钮。',
    add_multiple_date_steps:
      '步骤：选择所有日期和时间范围的组合，点击“添加日期”和“添加\n' +
      '          时间”，然后点击“添加”按钮。',
    pick_date: '选择日期',
    pick_start_date: '选择开始日期/时间',
    pick_end_date: '选择结束日期/时间',
    please_select_datetime: '请选择开始日期和结束日期',
    add_date: '添加日期',
    add_time: '添加时间',
    dates: '日期：',
    timeranges: '时间范围：',
    timeZone: '时区',
    image: '图像',
    imageName: '图像名',
    addImage: '新增图像',
    editImage: '编辑图像',
    eta: '预计时间',
    browse: '浏览',
    imageSite: '图像站点',
    imageRemark: '图像备注',
    selectedImage: '已选图像',
    poster: '海报',
    posterType: '海报类别',
    teamIcon: '队伍图标',
    other: '其他',
    createConfig: '新增设置',
    defaultConfigHint: '默認',
    defaultConfigSearchBarHint: '搜索',
    config: '配置',
    configCode: '设置Code',
    configValue: '设置值',
    configDescribes: '设置描述',
    s3Url: 'S3地址',
    allSites: '全部站点',
    allSource: '全部来源类型',
    rank: '等级',
    processResult: '结算结果',
    winner: '贏家',
    dailyDetail: '日报详情',
    dailySummary: '每日全总览',
    officialWebData: '官方数据',
    packageWebData: '包网数据',
    payoutAmount: '派彩金额',
    indBet: '下注',
    indProfit: '下注派彩利润',
    memberDepositCount: '总充值人数',
    indBonusAmount: '奖励金额',
    indRebateAmount: '返点金额',
    indAdjustAmount: '资金修正金额',
    grossProfit: '毛利润',
    football: '足球',
    basketball: '篮球',
    esport: '电竞',
    competitionType: '赛事类别',
    competitionName: '赛事名称',
    competitionTime: '赛事时间',
    addCompetition: '新增赛事',
    editCompetition: '编辑赛事',
    addCompetitionCode: '新增赛事代码',
    editCompetitionCode: '编辑赛事代码',
    addMemberTreasureKey: '添加会员宝箱钥匙',
    deductMemberTreasureKey: '扣除会员宝箱钥匙',
    keyQuantity: '钥匙数量',
    keyQuantityBefore: '之前钥匙数量',
    keyQuantityAfter: '之后钥匙数量',
    checkInDate: '签到日期',
    consecutiveDay: '连续签到天数',
    summoner: '唤醒人',
    memberSummon: '被唤醒人',
    summonBonus: '唤醒彩金',
    depositBonus: '存款彩金',
    depositDayCount: '存款天数',
    redirectUrlApp: 'APP跳转链接',
    redirectUrlWeb: 'Web跳转链接',
    redirectUrlH5: 'H5跳转链接',
    popUpDurationSeconds: '弹窗持续时间(秒)',
    popUpTime: '弹窗时间',
    notificationType: '通知类型',
    notificationContent: '通知内容',
    unbind: '解绑',
    winloss: '总输赢',
    profit2: '存提差',
    affiliateBetRebateRecord: '代理投注返水记录',
    affiliateBetRebateDetails: '代理投注返水详情',
    adjustRebateAmount: '调整返水金额',
    prizeLevel: '奖等',
    addLotteryRecord: '新增彩票记录',
    finalAmount: '最终金额',
    rewardType: '奖励类别',
    refereeEligibility: '受邀人参与条件',
    oneTimeBonusSetting: '一次性人头奖励设置',
    depositRebateSetting: '充值返佣设置',
    betRebateSetting: '投注返佣设置',
    referrerEligibility: '邀请人参与条件',
    affiliateLoginName: '代理用户名（逗号分隔）',
    needPhoneVerified: '需要验证手机号',
    needIpUniqueInMonth: '注册IP一个月内唯一',
    refereeMinTotalDeposit: '最小存款金额',
    refereeMinTotalBet: '最小总有效流水',
    minReferCount: '最小邀请人数',
    maxReferCount: '最大邀请人数',
    bonusAmountRules: '红利金额规则',
    referDistance: '邀请层级',
    depositRebateRate: '充值返佣比例',
    maxTotalBonusAmount: '每人奖金上限',
    maxDailyBonusAmount: '每日每人奖金上限',
    rebateRateSlot: '老虎机返佣比例',
    rebateRateFish: '捕鱼返佣比例',
    rebateRateLive: '真人返佣比例',
    rebateRateSport: '体育返佣比例',
    rebateRatePoker: '棋牌返佣比例',
    allMembers: '所有会员',
    selectedVipLevels: '指定VIP等级',
    selectedAffiliates: '指定代理',
    virtualAmount: '虚拟奖金金额',
    virtualMultiplier: '虚拟奖金倍数',
    batchDistributeRebate: '批量发送返水',
    distribute: '派发',
    totalAmountDistribute: '派发奖金总数',
    noOfBonusRecord: '奖金记录总数',
    whitelist: '白名单',
    blacklist: '黑名单',
    accessType: '访问类型',
    domainType: '域名类型',
    valueType: '值类型',
    ipList: 'IP组',
    ipRange: 'IP范围',
    countryCode: '国家代码',
    lower: '下限',
    upper: '上限',
    addMemberLotteryRecord: '新增会员彩票记录',
    addFakeLotteryRecord: '新增假彩票记录',
    approveSummonRecord: '批准唤醒记录',
    updateAffiliateLevel: '编辑代理等级',
    memberMoney: '会员余额',
    memberPoint: '会员积分',
    eggs: '虚拟金额',
    memberInquiry: '会员咨询',
    depositApplication: '存款申请人数',
    withdrawApplication: '提款申请人数',
    affiliateApplication: '代理待认证数',
    memberApplication: '会员待认证数',
    memberInbox: '玩家发信数',
    totalMembers: '会员总数',
    currentMembers: '在线会员',
    todayDepositAmount: '今日存款',
    todayWithdrawAmount: '今日提款',
    todayWithdrawDepositProfit: '今日存提盈利',
    manualMoney: '手动派发金额',
    manualPoint: '手动派发积分',
    totalGrossProfit: '总净盈利',
    casinoBetAmount: '真人下注',
    casinoProfit: '真人盈利',
    casinoRollingAmount: '真人流水',
    casinoGrossProfit: '真人净盈利',
    slotBetAmount: '电子下注',
    slotProfit: '电子盈利',
    slotRollingAmount: '电子流水',
    slotGrossProfit: '电子净盈利',
    sportBetAmount: '体育下注',
    sportProfit: '体育盈利',
    sportRollingAmount: '体育流水',
    sportGrossProfit: '体育净盈利',
    miniGameBetAmount: '小游戏下注',
    miniGameProfit: '小游戏盈利',
    miniGameRollingAmount: '小游戏流水',
    miniGameGrossProfit: '小游戏净盈利',
    unsuccessFrom30min: '30分钟前的未成功',
    unsuccessIn10time: '未成功10次的渠道',
    platformAccountName: '平台账号名',
    subRegCount: '下级注册人数',
    subDepositCount: '下级存款人数',
    ftdDownlineAmount: '下级首存金额',
    changeReferrer: '更改推荐人',
    memberShareRatio: '玩家返水比例',
    updateShareRatio: '编辑返水比例',
    downlineMembers: '下线人数',
    todayRegCount: '今日团队注册',
    yesterdayRegCount: '昨日团队注册',
    displayStartTime: '显示开始时间',
    displayEndTime: '显示结束时间',
    redirectType: '跳转类型',
    redirectUrl: '跳转链接',
    redirectButton: '跳转按钮',
    sendFrom: '发送人',
    expiryTime: '过期时间',
    noExpiryTime: '无过期时间',
    hasPopUp: '有弹窗',
    noPopUp: '无弹窗',
    selectedVIP: '指定VIP',
    affiliateBalance: '代理余额',
    memberBalance: '下线总余额',
    affiliatePoint: '我的POINT',
    memberCount: '下线会员数',
    affiliateWithdrawAmount: '代理提款金额',
    withdrawTransferDiff: '存提差',
    liveRolling: '下线总真人流水',
    slotRolling: '下线总老虎机流水',
    sportRolling: '下线总体育流水',
    recycleMoney: '回收余额',
    createMemberPointRecord: '创建会员积分记录',
    claimed: '已领取',
    notClaimed: '未领取',
    displayAmount: '显示金额',
    activate: '解禁',
    withdrawPassword: '提款密码',
    updateWithdrawPassword: '更新提款密码',
    self: '(自己)',
    levelUpBet: '升级流水',
    retainLevelBet: '保级流水',
    retainLevelDays: '保级天数',
    editSystemValueRules: '编辑系统设置值规则',
    addSystemValueRules: '新增系统设置值规则',
    walletInfo: '钱包信息',
    fiat: '法币',
    usdt: 'USDT',
    fiatBalance: '法币余额',
    usdtBalance: 'USDT余额',
    toggleWallet: '切换钱包',
    historyRecord: '历史数据',
    nonHistoryRecord: '非历史数据',
    productId: '产品ID',
    dayBetRebatePercentage: '每日投注返水百分比',
    needUniqueDevice: '需要独特设备',
    uniqueIpCount: '独特IP数量',
    uniqueDeviceCount: '独特设备数量',
    referFriendAnalysis: '邀请返佣分析',
    referRelationQuery: '邀请关系查询',
    referCount: '邀请人数',
    referBonus: '邀请奖金',
    successCount: '合格人数',
    betBonus: '投注返佣',
    batchFrozenMember: '批量冻结会员',
    threePointShots: '全场进10个三分球',
    shotPoints: '任意球员砍下30分',
    scoringShots: '任意球员拿到13个篮板',
    foulOut: '任意球员判罚离场',
    gender: '性别',
    Male: '男性',
    Female: '女性',
    startHour: '开始小时',
    endHour: '结束小时',
    oldBetAmount: '老站流水金额',
    showLogo: '显示图标',
    adjustTarget: '平账目标',
    ignoreSettlement: '忽略结算',
  },
  message: {
    startenddatemore3months: '开始与结束时间不能多于3个月',
    addRemarkSuccess: '新增备注成功',
    addSuccess: '新增成功',
    adjustSuccess: '平账成功',
    affiliateApproved: '代理已批准',
    affiliateDisabled: '代理已被禁用',
    autopaySuccess: '自动支付成功',
    cancelBbDachaSuccess: '此BB别墅杯赛事已取消',
    cancelSuccess: '取消成功',
    cancelDepositSuccess: '取消存款成功',
    cancelQuizSuccess: '此赛事已取消',
    cancelRolloverRecordSuccess: '取消流水记录成功',
    changeAffiliateSuccess: '更换代理成功',
    confirmUnbindAffiliateAccesss: '确认解绑上级代理',
    changeSuccess: '更改成功',
    commissionPaySuccess: '佣金发放成功',
    confirmCancel: '请确认是否要取消此记录, 此操作不可撤销',
    confirmCancelBbDacha: '请确认是否要取消此BB别墅杯赛事, 此操作不可撤销',
    confirmCancelMatch: '请确认是否要取消此赛事, 此操作不可撤销',
    confirmCancelQuiz: '请确认是否要取消此赛事问答, 此操作不可撤销',
    confirmCancelRebate: '请确认是否要取消返水记录, 此操作不可撤销',
    confirmCopy: '请确认是否将',
    confirmCopyTo: '复制至',
    confirmDelete: '请确认是否要删除这些数据, 此操作不可撤销',
    confirmDelivered: '请确认该礼品是否已送达, 此操作不可撤销',
    confirmRunAtOnce: '请确认是否立即执行这个任务(即使任务处于未开启状态)',
    confirmEndMatch: '请确认是否结束该赛事, 此操作不可撤销',
    confirmRebate: '请确认是否发放所有返水奖金, 此操作不可撤销',
    confirmBatchRebate: '请确认是否发送已选择的返水奖金, 此操作不可撤销',
    confirmSingleRebate: '请确认是否发送此返水奖金, 此操作不可撤销',
    confirmRedeem: '请确认是否发折现该礼品 此操作不可撤销',
    confirmRefer: '请确认是否发放所有好友邀请奖金, 此操作不可撤销',
    confirmReset: '请确认是否要清除这些数据, 此操作不可撤销',
    confirmSettlement: '请确认是否结算?',
    confirmStartMatch: '请确认是否开始赛事?',
    confirmToAdjust: '请确认是否进行调整？一旦进行调整将无法再更改',
    confirmToChecking: '请确认是否将此数据返回至待审核?',
    confirmToPay: '请确认是否发放佣金?',
    confirmToCheck: '请确认是否更改用户资料',
    confirmUpdate: '请确认是否更新?',
    confirmUpdatePayment: '请确认是否更新支付?',
    confirmUnbind: '请确认是否解绑会员银行卡?',
    copyToClipboard: '已复制到剪贴板中',
    copySuccess: '成功复制',
    deleteSuccess: '删除成功',
    runAtOnceSuccess: '执行成功',
    dailyMinRegLimitCannotExceedMax: '每日最小注册限制不能大于最大注册限制',
    dailyMinFirstDepositCannotExceedMax: '每日最小存款金额不能大于最大存款金额',
    depositSuccess: '存款成功',
    editAmountSuccess: '编辑金额成功',
    editRemarkSuccess: '编辑备注成功',
    editSuccess: '编辑成功',
    editMemberDetail: '编辑会员信息',
    endMustAfterStart: '结束时间必须设置晚于开始时间',
    enterMemberDetails: '输入会员信息',
    failedToUploadImage: '上传图像失败',
    failedToUploadApp: '上传应用程序失败',
    freezeMemberSuccess: '冻结用户成功',
    unfreezeMemberSuccess: '解冻用户成功',
    found: '已找到',
    gameMatchEnded: '赛事已结束',
    giftDelivered: '礼品已送达',
    giftNoCashRedeemAmount: '奖品没有折现金额, 请先设置该奖品的折现金额',
    giftOutForDelivery: '礼品已在运送中',
    importSuccess: '导入成功',
    inputDigits: '请输入数字',
    inputPassword: '请输入密码',
    inputPasswordAgain: '请再输入密码',
    invalidFileType: '档案格式错误',
    length6To12: '字符串必须为 6 至 12 字',
    levelUpCreditMoreThan: '升级金额必须大于',
    logoutPlayerSuccess: '用户登出成功',
    maxGreaterThanMin: '最大值必须大于最小值',
    memberNotInSite: '会员不存在于此站点',
    affiliateNotInSite: '代理不存在于此站点',
    memberFreeGameLogin: '玩家账号, 超过一笔时以逗号分隔(最多200笔账号)',
    memberFreeGameRound: '局数',
    memberFreeGameIds:
      '可使用游戏 ID, 超过一笔时以逗号分隔; 长度 (含逗号) 上限 200',
    multipleQuerySeparatedBySpace: '使用空格来区分多个搜索条件',
    multiwheelprizeform: '输入 0 以代表大奖',
    noAvailablePrivilege: '当前无可用的优惠',
    passwordLength: '密码必须多于 6 字并少于 12 字',
    paymentUpdate: '是否需要复制？如无需复制请按开始',
    paySuccess: '支付成功',
    pleaseEnterAffiliateNameOrAffiliateCode: '请输入代理名或者代理编码',
    promoDistributionSuccess: '发放奖金成功',
    redeemCashSuccess: '奖品折现成功',
    rebateSuccess: '返水派发中，请稍后再刷新查看记录',
    reenterPassword: '请重新输入密码',
    referSuccess: '派发好友邀请奖金成功',
    registerSuccess: '注册成功',
    registerSuccessInd: '注册成功. 请设置新代理支付显示',
    replySuccess: '回复成功',
    removePreviousLevel: '请移除上级',
    removeRemarkSuccess: '删除备注成功',
    replyFeedback: '回复用户反馈',
    resetSuccess: '清除成功',
    resendSuccess: '重发成功',
    selectDateNotMoreThan3Month: '日期范围不能超过3个月，请重新选择',
    selectSiteFirst: '请先选择站点',
    selectSiteAndGameTypeFirst: '请先选择站点和游戏类型',
    selectPreviousLevel: '请选择上级',
    selectPaymentType: '请输入支付类型',
    selectMobilePayment: '请先选择手机支付类型',
    selectWebPayment: '请先选择网页支付类型',
    settlementToChecking: '成功更新至待审核',
    settlementToPay: '成功更新至待结算',
    settled: '成功结算',
    startMustBeforeEnd: '开始时间必须设置早于结束时间',
    success: '成功',
    summarySuccess: '已选择时段的汇总即将生成，请稍等',
    transferSuccess: '转账成功',
    twoPasswordNotMatch: '重新输入密码不相同',
    unbindSuccess: '成功解绑会员银行卡',
    unlockMemberSuccess: '成功解锁账号',
    unlockUserSuccess: '成功解锁用户',
    updateBelongTypeSuccess: '网页类型更新成功',
    updateCommissionModelSuccess: '更新分红模式成功',
    updateCommissionRateSuccess: '更新分红比率成功',
    updateFinancialLevelSuccess: '更新会员组别成功',
    updatePasswordSuccess: '更新密码成功',
    updatePayment: '提交付款',
    updateProceed: '更新节点以进行付款编辑',
    updateRiskLevelSuccess: '更新风险等级成功',
    updateSuccess: '更新成功',
    updateWithdraw: '成功提交出款',
    updateTimeTypeSuccess: '更新时间类型成功',
    updateToApplySuccess: '成功更新至申请中状态',
    updateToBeforePaidSuccess: '成功更新至待支付状态',
    updateToFailSuccess: '成功更新至失败状态',
    updateToPaySuccess: '成功更新至支付中状态',
    updateToSuspendSuccess: '成功更新至中止状态',
    updateToUnderReviewSuccess: '成功更新至审核中状态',
    updateUserTypeSuccess: '更新账号类型成功',
    updateVIPLevelSuccess: '更新VIP等级成功',
    updateWithdrawTypeSuccess: '更新提款类型成功',
    updateShareRatioSuccess: '更新佣金比例成功',
    useCommaToSeparateDomain: '如要加入多个域名, 请使用"," 来分开',
    validateActionRequired: '动作必填',
    validateActivePlayerRequired: '活跃玩家必填',
    validateAdsTypeRequired: '广告类型必填',
    validateAppTypeRequired: '请选择至少一个应用程序类型',
    validateAdjustAmountRequired: '平账金额必填',
    validateAdjustReasonRequired: '平账原因必填',
    validateAdjustTypeRequired: '平账类型必填',
    validateAdjustmentRequired: '调整金额必填',
    validateAffiliateFbIdRequired: 'Facebook 像素ID必填',
    validateAffiliateFbTokenRequired: 'Facebook 像素令牌必填',
    validateAffiliateFbUrlRequired: 'Facebook 像素域名必填',
    validateAffiliateCodeRequired: '代理代码必填',
    validateAffiliateLevelRequired: '代理级别必填',
    validateAffiliateNameRequired: '代理名必填',
    validateAliasRequired: '别名必填',
    validateAllBonusRequired: '所有奖金显示必填',
    validateAlphaNumericOnly: '请输入字母和数字',
    validateAmountRequired: '金额必填',
    validateAnnouncementTypeRequired: '公告类型必填',
    validateAnswerOneRequired: '题目一答案必选',
    validateAnswerOneAtLeastTwoChoices: '题目一答案必需至少有两个选项',
    validateAnswerTwoRequired: '题目二答案必选',
    validateAnswerTwoAtLeastTwoChoices: '题目二答案必需至少有两个选项',
    validateAnswerThreeRequired: '题目三答案必选',
    validateAnswerThreeAtLeastTwoChoices: '题目三答案必需至少有两个选项',
    validateAtLeastSixChar: '请输入至少6个字符',
    validateAwardsRequired: '奖池必填',
    validateAwayTeamRequired: '客队必填',
    validateAwayTeamIconRequired: '客队图标必须上传',
    validateAwayTeamResultRequired: '客队得分必填',
    validateBankRequired: '银行必填',
    validateBankCardNumberRequired: '银行卡号码必填',
    validateBankCodeRequired: '银行代码必填',
    validateBankNameRequired: '银行名必填',
    validateBankTypeRequired: '银行类型必填',
    validateBeanNameRequired: '对象名称必填',
    validateBetAmountRequired: '投注金额必填',
    validateBonusRequired: '奖金必填',
    validateBonusAmountRequired: '奖金数额必填',
    validateBonusAmountRatioRequired: '奖金数额比率必填',
    validateBonusTypeRequired: '奖金类型必填',
    validateCardAccountRequired: '银行卡账号必填',
    validateCardAddressRequired: '银行卡地址必填',
    validateCardNumberRequired: '银行卡号码必填',
    validateCategoryRequired: '类别必填',
    validateCauseRequired: '原因必填',
    validateChoiceOneRequired: '请输入主队和客队',
    validateChoiceTwoRequired: '请选择游戏类型',
    validateChoiceThreeRequired: '请选择游戏类型',
    validateChoiceRequired: '选项必填',
    validateClassNameRequired: '类别名必填',
    validateConfigGroupRequired: '设置组必填',
    validateCodeRequired: '代码必填',
    validateCodeExist: '代码已存在，请输入其他代码. 现有代码 : ',
    validateColorRequired: '颜色必填',
    validateCommissionRequired: '比率必填',
    validateCommissionFormat: '比率必须介于 0 至 1 之间',
    validateCommissionModelRequired: '需要佣金模式',
    validateConfirmPasswordRequired: '确认密码必填',
    validateConfirmPasswordSize: '确认密码必须在 6-12 个字之间',
    validateContentRequired: '内容必填',
    validateCopyFinancialLevel: '请选择至少一个会员组别来复制',
    validateCountryImageRequired: '国家图像必填',
    validateCountryRequired: '国家必填',
    validateCronExpressionFormat: 'Cron表达式格式错误',
    validateCronExpressionRequired: 'Cron表达式必填',
    validateCurrencyRequired: '货币必填',
    validateCurrencyCodeRequired: '货币代码必填',
    validateCurrencyNameRequired: '货币名称必填',
    validateCustomerSupportAddressFormat: '客服地址JSON字符串格式错误',
    validateDailyMinRequired: '每日最低转盘次数必填',
    validateDailyMaxRequired: '每日最高转盘次数必填',
    validateDateRequired: '日期必填',
    validateDayWithdrawCountRequired: '每日提款次数必填',
    validateDefaultBonusRequired: '默认奖金必填',
    validateDepositAmountRequired: '存款金额必填',
    validateDepositExchangeRateRequired: '存款兑换率必填',
    validateDepositPerSpinRequired: '每次转盘存款金额必填',
    validateDesktopImageRequired: '桌面图像必填',
    validateDesktopBannerRequired: '桌面广告图必填',
    validateDeviceRequired: '设备必填',
    validateDomainRequired: '域名必填',
    validateEmailRequired: '邮箱必填',
    validateEmailFormat: '请输入正确的邮箱格式',
    validateEndDateRequired: '结束日期必填',
    validateEndTimeRequired: '结束时间必填',
    validateExchangeRateRequired: '兑换率必填',
    validateExpressCompanyRequired: '快递公司必填',
    validateFailReasonRequired: '失败原因必填',
    validateFeeRateRequired: '收费率必填',
    validateFileRequired: '应用程序必上载',
    validateFreezeTypeRequired: '冻结类型必填',
    validateFinancialLevelRequired: '会员组别必填',
    validateFrequencyRequired: '频率必填',
    validateGameCodeRequired: '游戏代码必填',
    validateGameNameRequired: '游戏名称必填',
    validateGameTypeRequired: '游戏类型必填',
    validateHomeTeamRequired: '主队必填',
    validateHomeTeamIconRequired: '主队图标必须上传',
    validateHomeTeamResultRequired: '主队得分必填',
    validateIconRequired: '图标必填',
    validateIdentifyCodeRequired: '识别码必填',
    validateIpRequired: 'IP 必填',
    validateJobNameRequired: '任务名称必填',
    validateLabelRequired: '标签必填',
    validateLevelRequired: '级别必填',
    validateLevelNameRequired: '级别名称必填',
    validateLevelUpCreditRequired: '升级金额必填',
    validateLimitNumberRequired: '限量必填',
    validateLoginNameRequired: '登录名必填',
    validateLoginNameSize: '登录名必须在 6-12 个字之间',
    validateMaintenanceTimeRequired: '维护时间必选',
    validateMallCodeRequired: '商户号必填',
    validateMallKeyRequired: '秘钥必填',
    validateMallNameRequired: '支付编码必填',
    validateMatchTitleRequired: '赛事标题必填',
    validateMatchTimeRequired: '赛事时间必填',
    validateMaxDailyWithdrawRequired: '每日最高提款金额必填',
    validateMaxDailyWithdrawNumber: '每日最高提款金额不能少过或等于0',
    validateMaxDailyWithdrawGreater: '每日最高提款金额必须多过最高提款金额',
    validateMaxDailyWithdrawTimesRequired: '每日最多提款次数必填',
    validateMaxDailyWithdrawTimesNumber: '每日最多提款次数不能少过或等于0',
    validateMaxDepositRequired: '最高存款必填',
    validateMaxDepositGreater: '最高存款必须多过最低存款',
    validateMaxWithdrawAmountRequired: '最高提款金额必填',
    validateMaxWithdrawAmountNumber: '最高提款金额不能少过或等于0',
    validateMaxWithdrawAmountGreater: '最高提款金额必须多过最低提款金额',
    validateMemberTypeRequired: '用户类型必填',
    validateMemberIdRequired: '用户ID必填',
    validateMenuTitleRequired: '菜单标题必填',
    validateMinBalanceRequired: '最低余额必填',
    validateMinDepositRequired: '最低存款必填',
    validateMinDepositLesser: '最低存款金额必须少过最高存款金额',
    validateMinWithdrawRequired: '最低提款金额必填',
    validateMinWithdrawNumber: '最低提款金额不能少过或等于0',
    validateMinWithdrawLesser: '最低提款金额必须少过最高提款金额',
    validateMobileImageRequired: '手机图像必填',
    validateMobileBannerRequired: '手机广告图必填',
    validateMobileMessageRequired: '手机提示信息必填',
    validateNameRequired: '名称必填',
    validateNeedRegisterRequired: '需要注册必填',
    validateNetProfitRequired: '净利润必填',
    validateNextActivationTimeRequired: '下次启动时间必填',
    validateNextGetBetEndTime: '下次取投注记录结束时间必填',
    validateNextGetBetPage: '下次取投注记录页面必填',
    validateNextGetBetStartTime: '下次取投注记录开始时间必填',
    validateNoNullValue: '所有的值不能为空',
    validateNoRepeatAmount: '不能输入相同金额',
    validateNumberOnly: '请只输入数字',
    validateNumberFourDecimalOnly: '请只输入大于0并最多4位小数的数字',
    validateOneTimeSpinRequired: '单次转盘必填',
    validateOnlyThreeChar: '请输入3个字符',
    validateOrderTrackingNoRequired: '订单号必填',
    validateOsRequired: '请选择至少一个操作系统',
    validateParamRequired: '参数必填',
    validatePasswordRequired: '密码必填',
    validatePasswordSize: '密码必须在 6-12 个字之间',
    validatePaymentFeeFormat: '支付手续费必须介于 0 至 1 之间',
    validatePaymentNameRequired: '支付名称必填',
    validatePaymentTypeRequired: '支付类型必填',
    validatePayResultTypeRequired: '支付结果显示方式必填',
    validatePayTypeRequired: '支付类型必填',
    validatePayTypeCodeRequired: '支付类型代码必填',
    validatePayTypeNameRequired: '支付类型名称必填',
    validatePayTypeNameCodeDifferent: '支付类型名称与支付类型代码不能为相同值',
    validatePayoutRequired: '派彩必填',
    validatePlatformRequired: '平台必填',
    validatePlatformAccountRequired: '平台账号必填',
    validatePlatformCodeRequired: '平台代码必填',
    validatePlatformDetailsRequired: '已选择的平台必须填上金额',
    validatePlatformFeeFormat: '场馆费必须介于 0 至 1 之间',
    validatePlatformNameRequired: '平台名称必填',
    validatePlatformSelected: '所选平台重复',
    validatePointsRequired: '总积分必填',
    validatePriorityRequired: '优先值必填',
    validatePrivilegeRequired: '优惠名必填',
    validatePromoCodeRequired: '优惠代码必填',
    validatePromoTypeRequired: '优惠类型必填',
    validateQueryNumberRequired: '搜索数量必填',
    validateQueryRestrictionRequired: '搜索限制必填',
    validateQuestionOneRequired: '题目一必填',
    validateQuestionRequired: '题目必填',
    validateQuizTimeRequired: '赛事问答时间必填',
    validateQuizTitleRequired: '赛事问答标题必填',
    validateRateRequired: '比率必填',
    validateRealNameRequired: '真实名字必填',
    validateReasonRequired: '原因必填',
    validateReasonTypeRequired: '原因类型必填',
    validateRebateAmountRequired: '返水金额必填',
    validateRebatePercentageRequired: '返水比例必填',
    validateRedeemPointsRequired: '兑换分数必填',
    validateMaxRebateRequired: '最大返水额必填',
    validateRecipientRequired: '收件人必填',
    validateRecordTimeRequired: '记录时间必填',
    validateRedirectRequired: '域名转址必填',
    validateReenterPasswordRequired: '请重新输入密码',
    validateRemarkRequired: '备注必填',
    validateRequestUrlRequired: '回调地址必填',
    validateResultDateRequired: '开彩日期必填',
    validateResultNumber: '开彩三位数号码必填',
    validateRebateFormat: '返点必须介于 0 至 1 之间',
    validateRevenueShareFormat: '收入必须介于 0 至 1 之间',
    validateRewardGroupRequired: '奖金组必填',
    validateRewardGroupNameRequired: '奖金组名称必填',
    validateRewardGroupDownlineQuotaRequired: '最高下线人数必填',
    validateRewardGroupDownlineQuotaFormat: '最高下线人数必须大于0',
    validateRiskLevelRequired: '风险等级必填',
    validateRoleRequired: '角色必填',
    validateRoleNameRequired: '角色名称必填',
    validateRolloverRequired: '倍数必填',
    validateGameRolloverRequired: "游戏类型流水必选",
    validateGameRolloverSelectRequired: "请选择游戏类型",
    validateRoundRequired: '局数必填',
    validateRulesRequired: '彩金配置必填',
    validateSchemaNameRequired: '模式名必填',
    validateSequenceRequired: '排序必填',
    validateSiteRequired: '站点必填',
    validateSiteCodeRequired: '站点代码必填',
    validateStageRequired: '关卡必填',
    validateStartTimeRequired: '开始时间必填',
    validateStateRequired: '状态必填',
    validateStatusRequired: '状态必填',
    validateStepsRequired: '步数配置必填',
    validateStepBonusRequired: '奖金配置必填',
    validateStopAfterFailureRequired: '失败后停止必填',
    validateSubjectRequired: '标题必填',
    validateSuccess: '验证成功',
    validateSupportTypeRequired: '支持类型必填',
    validateSureWinBonusRequired: '必中奖金必填',
    validateSureWinMinSpinRequired: '必中奖最低转盘次数必填',
    validateTeamGroupRequired: '队伍组别必填',
    validateTeamIconRequired: '队伍图标必须上传',
    validateTeamNameRequired: '队伍名称必填',
    validateTeamNameEnRequired: '团队英文名称必填',
    validateTeamNameLocalRequired: '团队当地名称必填',
    validateTeamOneRequired: '团队一必填',
    validateTeamOneIconRequired: '团队一图标必须上传',
    validateTeamTwoRequired: '团队二必填',
    validateTeamTwoIconRequired: '团队二图标必须上传',
    validateTelephoneRequired: '电话号码必填',
    validateTelegramUsernameRequired: 'Telegram用户名必填',
    validateThirdSerialNumberRequired: '第三方流水号必填',
    validateThreeDigitsNumbeRequired: '三位数字必填',
    validateTimeTypeRequired: '时间类型必填',
    validateTitleRequired: '标题必填',
    validateTransactionIdRequired: '注单号必填',
    validateTransactionStatusRequired: '注单状态必填',
    validateTriggerTypeRequired: '触发类型必填',
    validateTypeRequired: '类型必填',
    validateUrlFormat: '请输入正确的域名地址',
    validateUsernameRequired: '用户名必填',
    validateUsernameSize: '用户名必须在 6-12 个字之间',
    validateUserTypeRequired: '用户类型必填',
    validateValidBetRequired: '有效投注必填',
    validateValidBetPerSpinRequired: '每次转盘所需有效投注必填',
    validateValueRequired: '数值必填',
    validateVIPRequired: 'VIP必填',
    validateWalletTypeRequired: '钱包类型必填',
    validateWayRequired: '设备必填',
    validateWebMessageRequired: '网页提示信息必填',
    validateWholeNumberOnly: '请输入整数',
    validateWinnerRequired: '获胜队伍必选',
    validateWithdrawAmountRequired: '提款金额必填',
    validateWithdrawChannel: '排序不可重复',
    validateWithdrawExchangeRateRequired: '提款兑换率必填',
    validateWithdrawPlatformNameRequired: '提款平台名称必填',
    validateWithdrawTypeRequired: '提款类型必填',
    validateTigerCardPeriodRequired: '期数必填',
    validateTigerCardTimeRequired: '抽卡时间必填',
    validateTigerCardBonusTimeRequired: '发奖时间必填',
    validateTigerSumAwardRequired: '总奖金必填',
    validateVirtualCountRequired: '数量必填',
    validateHostRequired: '域名必填',
    validatePortRequired: '端口必填',
    validateSignNameRequired: '公司名字必填',
    validateSearchCondition: '请输入至少一个搜索条件',
    validateSecretIdRequired: '密码必填',
    validateSecretKeyRequired: '密钥必填',
    validateAppIdRequired: '应用ID必填',
    validateTemplateIdRequired: '模板ID必填',
    requestExportToExcelDone1: '请求已经成功发送，请按 ',
    requestExportToExcelDone2: ' 进行查看进度与下载。',
    validateImageRequired: '图像必填',
    validateImageNameRequired: '图像名必填',
    validatePosterTypeRequired: '海报类别必填',
    validateConfigCodeRequired: '设置Code必填',
    validateConfigValueRequired: '设置值必填',
    validateConfigTypeRequired: '设置值类型是必选的',
    resultCalculateSuccess: '结果结算成功',
    buildIsInQueue: '构建等待中',
    roleMustOnlyOne: '请选择一个需要授权的角色',
    validateCompetitionTypeRequired: '赛事类别必填',
    validateCompetitionNameRequired: '赛事名称必填',
    validateCompetitionTimeRequired: '赛事时间必填',
    validateKeyQuantityRequired: '钥匙数量必填',
    validateMonthRequired: '年/月必填',
    validateTimeRequired: '时间必填',
    validateShareRatioFormat: '佣金比例必须介于 0 至 1 之间',
    syncMemberDetailSuccess: '同步会员信息成功',
    timeExist: '时间已存在',
    wrongPopupTime: '弹窗时间错误。',
    selectPopupTime: '请选择弹窗时间.',
    summonSuccess: '唤醒成功',
    updateAffiliateLevelSuccess: '成功编辑代理等级',
    validateMinDayRegLimitRequired: '每日最小注册限制必填',
    validateMaxDayRegLimitRequired: '每日最大注册限制必填',
    validateMinDayDepositAmountRequired: '每日最小存款金额必填',
    validateMaxDayDepositAmountRequired: '每日最大存款金额必填',
    validateDayRegProbabilityRequired: '每日注册概率必填',
    validateDayDepositAmountProbabilityRequired: '需要每日首次存款概率必填',
    validateMediaDescRequired: '视频名称必填',
    validateMediaUrlRequired: '视频链接必填',
    validateDisplayUrlRequired: '视频展示链接必填',
    validateModeRequired: '模式必填',
    validateAutoplayRequired: '自动播放必填',
    validatePlanOddsRequired: '投资利率必填',
    validatePlanDaysRequired: '投资期限必填',
    validatePlanLimitMinRequired: '最小投资限额必填',
    validatePlanLimitMaxRequired: '最大投资限额必填',
    affiliateReactivated: '解禁成功',
    required_6_digits_code: '只能六个字符',
    required_only_digits_and_alphabet: '只许字母或数字',
    validateProductIdRequired: '产品ID必填',
    validateBetRebatePercentageRequired: '每日投注返水百分比必填',
    validateLoginNameNoSpecialCharacter: '登录名只能包含下划线（_），数字和字母。',
    validateAdjustColumnRequired: '平账目标必填',
  },
  menu: {
    undefined: '',
    Dashboard: '仪表盘',
    Member: '会员',
    'Member List': '会员列表',
    'Member List ID': '会员列表',
    'Member Details': '会员信息',
    'Member Edit Log': '会员编辑日志',
    'Member Feedback': '会员反馈',
    'Member Refer': '邀请列表',
    'Member Refer Summary': '会员邀请统计',
    'Member Bank Card History': '会员绑卡记录',
    'Member Freeze Record': '会员账号冻结记录',
    'Member Amount Adjust': '会员平账记录',
    'Member Privilege Blacklist': '会员优惠黑名单',
    'Member Sms': '发送短信记录',
    'Import Bet Records': '导入投注记录',
    'Member Transfer Record': '会员转账记录',
    'Member VIP': '会员等级列表',
    'Member Bet Record': '会员投注记录',
    'PG Pending': 'PG待处理记录',
    'Member Privilege': '会员优惠记录',
    'Member Rollover Record': '会员流水记录',
    'Member Distribute Privilege': '会员发放奖金',
    'Member Profit Ranking': '盈利排名',
    'Member Affiliate Change Log': '会员代理更换记录',
    'Member Group Send SMS': '会员群发消息',
    'Member Vip Upgrade': '会员VIP升级记录',
    Affiliate: '代理',
    'Affiliate Amount Adjust': '代理平账记录',
    'Affiliate Summary': '代理总结',
    'Affiliate Monthly Settlement': '代理结算记录',
    'Affiliate Monthly Settlement Detail': '代理结算信息',
    'Affiliate Settlement Checking': '代理审核发放',
    'Affiliate Settlement Payment': '代理待结算记录',
    'Affiliate Domain Management': '代理域名管理',
    'Affiliate List': '代理列表',
    'Affiliate Details': '代理信息',
    'Affiliate Financial Management': '代理支付管理',
    'Affiliate Facebook Pixel Management': '代理 Facebook 像素管理',
    'Affiliate Deposit Display': '代理支付显示',
    'App Version': '应用程序版本控制',
    'Auto Withdraw Setting': '自动提款设置',
    'Site Affiliate Commission': '站点代理分红',
    'Aff Announcement Management': '代理公告',
    'Affiliate Announcement': '代理公告',
    'Affiliate Announcement Type': '代理公告类型',
    'Affiliate Member Dummy Record': '代理会员虚拟数据',
    'Deposit Management': '存款管理',
    'Online Deposit': '线上存款',
    'Deposit Record': '存款记录',
    'Withdrawal Auto Process': '自动提款流程',
    'Withdrawal Management': '提款管理',
    'Withdrawal Process': '提款流程',
    'Withdrawal Affiliate Process': '代理提款流程',
    'Withdrawal Record': '提款记录',
    'Withdrawal Affiliate Record': '代理提款记录',
    'Withdrawal Platform Balance': '提款平台余额',
    'Withdraw Review': '提款复查',
    'Withdrawal Bank Blacklist': '提款卡黑名单',
    'Withdraw Tips Setting': '提款提示设定',
    'Reward Group': '奖金组',
    Applying: '申请中',
    'Affiliate Applying': '申请中',
    'Affiliate Under review': '审核中',
    BW1: 'BW1',
    BW2: 'BW2',
    BW3: 'BW3',
    BW4: 'BW4',
    BW5: 'BW5',
    'To be affiliate paid': '待支付',
    'Affiliate Payment on going': '支付中',
    'Crypto Affiliate Payment on going': '数字币支付中',
    'Ewallt Affiliate Payment on going': '电子钱包支付中',
    'Alipay Affiliate Payment on going': '支付宝支付中',
    'Affiliate Automatic Payment': '自动支付',
    'Affiliate Suspend': '暂不处理',
    'Under review': '审核中',
    'To be paid': '待支付',
    'Payment on going': '支付中',
    'Crypto Payment on going': '数字币支付中',
    'Ewallt Payment on going': '电子钱包支付中',
    'Alipay Payment on going': '支付宝支付中',
    'Automatic Payment': '自动支付',
    'Waiting Auto Pay': '等待自动支付',
    'AutoWithdraw Under review': '提款审核',
    'AutoWithdraw Under review Risk': '风险提现终审',
    'AutoWithdraw Withdrawal Record': '提交记录',
    'Fail Review': '失败审核',
    Suspend: '暂不处理',
    Promotion: '优惠',
    'Privilege Info': '优惠列表',
    'Home Banner': '首页广告',
    'Promotion pages': '优惠页面',
    'Edit Promo': '编辑优惠页面',
    'Multi Wheel': '幸运轮盘',
    'Multi Wheel List': '幸运轮盘玩家列表',
    'Multi Wheel Setting': '幸运轮盘设置',
    'Multi Wheel Additional List': '幸运轮盘加票设置',
    'Team Votes': '团队投票',
    'Team Votes Records': '团队投票记录',
    'Team Votes Settings': '团队投票设置',
    'Tiger Card': '虎卡',
    'Telephone Numbers': '电话号码',
    'Site Email': '邮件地址',
    'Ads Popout': '弹窗管理',
    'Add Ads Popout': '新建弹窗',
    'Edit Ads Popout': '编辑弹窗',
    'Red Packet Rain': '红包雨',
    'Red Packet Rain List': '紅包雨玩家列表',
    'Member Free Game': '玩家免费局列表',
    Lottery: '彩票',
    'Lottery Record List': '彩票记录',
    'Lottery Result List': '彩票结果',
    'Vietnam Lottery Record List': '越南彩票记录',
    'Vietnam Lottery Result List': '越南彩票结果',
    'VIP Rebate Record': 'VIP返水记录',
    'Refer Friend': '推荐人记录',
    'Bet Rebate Record': '投注返水记录',
    'Refer Friend Rebate Record': '邀请好友流水返点记录',
    Gift: '奖品兑换管理',
    'Gift Info': '奖品列表',
    'Gift Record': '奖品兑换记录',
    'Promo Application': '优惠申请记录',
    'Game Steps': '转盘闯关管理',
    'BB Dacha': 'BB别墅杯',
    'VIP Wheel': 'VIP转盘',
    'Bet Wheel': '投注转盘',
    'Piggy Bank': '存钱罐',
    'Loss Rebate Record': '亏损返水记录',
    'Fish Red Packet': '捕鱼红包配置',
    'Bet Game Type Rebate Record': '投注游戏类型返水记录',
    'Site Interest Plan': '利息宝投资方案',
    'Interest Plan Order Record': '利息宝投资记录',
    'Promo Type': '优惠类型管理',
    Treasure: '宝箱记录',
    'Site Management': '站点管理',
    Message: '信息',
    Announcement: '公告',
    'Announcement Type': '公告类型',
    'VIP Rebate': 'VIP 返水',
    VIP: 'VIP',
    Game: '游戏',
    'Game Tag': '游戏标签',
    'TFG Member Game Map': 'TFG游戏码绑定',
    'Financial Level': '会员组别',
    'Risk Level': '风险等级',
    'Ip Label': 'IP 标签',
    'Site Platform Management': '平台站点管理',
    'Game Match': '电竞体育保险投注',
    'Game Quiz': '全民竞猜',
    'Member Rebate': '会员特殊返水比例',
    Questionnaire: '有奖问答',
    UEFA: '欧洲杯',
    'UEFA Team': '欧洲杯队伍',
    'UEFA Match': '欧洲杯赛事',
    'UEFA Winner': '欧洲杯获胜名单',
    'Refer Friend Manager': '邀请好友管理',
    'Sport Match': '中超角逐',
    'Nba Match': 'NBA季后赛',
    'Nba Match Preseason': 'NBA季前赛',
    Redirect: '浮窗管理',
    Permission: '权限',
    Menu: '菜单',
    Roles: '角色',
    User: '用户',
    Report: '报表',
    'Privilege Record': '优惠记录',
    'Summary Report': '总结报表',
    'Privilege Report': '优惠报表',
    'Deposit Report': '存款报表',
    'Withdraw Report': '提款报表',
    'Finance Report': '财务报表',
    'Affiliate Report': '代理报表',
    'Affiliate Deposit Members': '代理报表存款会员',
    'Site Member Report': '会员报表',
    'Deposit Member Detail': '今日充值会员列表',
    System: '系统',
    'Operator Log': '后台日志',
    'Member Platform': '用户平台设置',
    Site: '站点',
    Config: '设置',
    Currency: '币别',
    Platform: '平台',
    'Site Platform': '平台站点',
    Schedule: '定时器',
    'Platform Account': '平台账号',
    'Data Dictionary': '数据字典',
    'Manual Summary': '手动汇总',
    'Affiliate Manual Summary': '手动代理汇总',
    'Payment Management': '支付管理',
    'Bank Info': '银行信息',
    'Bank Card': '银行卡',
    'Payment Type': '支付类型',
    'Payment Display List': '支付平台显示',
    'Platform Game Report': '场馆游戏报表',
    Payment: '支付',
    'Add Payment': '新增支付',
    'Edit Payment': '编辑支付',
    CurrencyRate: '汇率',
    'Payment Display': '支付显示',
    'Withdraw Platform': '提款平台',
    'Withdraw Setting': '提款设置',
    'Site Withdraw Platform': '提款平台站点',
    'Deposit Setting': '存款设置',
    'Withdraw Display': '提款显示',
    'Payment Bank Management': '支付银行管理',
    'Withdraw Platform Bank Management': '提款平台银行管理',
    'Finance Feedback': '存提催单',
    'Summary Register Report': '注册总结报表',
    'Summary Fdp Report': 'Fdp总结报表',
    'Summary Deposit Report': '存款总结报表',
    'Summary Withdraw Report': '提款总结报表',
    'Summary Active Report': '活跃会员总结报表',
    'Summary Member Report': '日会员总结报表',
    'Email Setting': '邮件设置',
    'SMS Setting': '短信设置',
    DownloadManager: '下载中心',
    GoogleAuth: 'Google 验证中心',
    'Error Queue': '错误消息',
    'Image Setting': '图片设置',
    'Privi Christmas': '圣诞快乐',
    'Affiliate APK Build': '代理构建APK',
    'Channel Summary': '渠道报表',
    'Adjustment Reason': '平账原因',
    'User Login Log': '用户登录日志',
    'Online Stats': '在线人数',
    'Promotion Activity': '优惠活动',
    'Platform Competition': '平台赛事',
    'Platform Competition Code': '平台赛事代码',
    'Event Treasure Key': '活动宝箱钥匙记录',
    'Event Check In Record': '活动签到记录',
    'User Action Log': '用户操作日志',
    'Competition Management': '赛事管理',
    'Competition List': '赛事列表',
    'Competition Code': '赛事代码',
    'Affiliate Recalculate': '代理重算',
    Utils: '工具',
    'Ip Location': 'Ip查询',
    'Member Summon Reward Record': '召唤奖励',
    'Redirect Notification': '页面跳转通知',
    'Member Summon Event': '精英召唤活动',
    'Member Summon List': '召唤列表',
    'Affiliate Bet Rebate Record': '代理投注返水记录',
    'UEFA Lottery': 'UEFA 彩票',
    'UEFA Lottery Record': 'UEFA 彩票记录',
    'UEFA Lottery Result': 'UEFA 彩票成绩',
    'Member Refer Event': '会员邀请活动',
    'Member Refer Record': '会员邀请记录',
    'Member Refer Reward List': '会员邀请奖励记录',
    'Refer Rebate': '邀请奖励',
    'Domain Access': '域名访问设置',
    'KRW Stats Report': '韩国站点数据报表',
    'Member Refer Pak': '邀请列表-巴基斯坦',
    'Member Refer Summary Pak': '会员邀请统计-巴基斯坦',
    Pm: '会员私信',
    'MQ Log': 'MQ日志',
    'System Config': '系统设置',
    'Monitor And Notification': '监控/通知',
    'Promotion Coupon': '存款券',
    'Member Invite Limit': '会员邀请限制',
    'Member Point Record': '会员积分记录',
    'Affiliate Change Log': '更换代理日志',
    'Media Display Settings': '媒体显示设置',
    'Vip Report': 'VIP投注汇总报表',
    'System Value Rules': '系统设置值规则',
    'Sites Config': '站点设置',
    'Mooncake Fest Spin Record': '中秋节转盘2024',
    'Platform WC': 'WC平台',
    'Site Platform Report': '站点平台报表',
    'Refer Friend Eligibility': '全民代理',
    'Member Refer Analysis': '邀请返佣分析',
    'TF Gaming Config': 'TF Gaming 设置',
    'LH Old Data': '雷火老站数据',
    'Affiliate Point Record': '代理积分记录',
    'Member Ratio Edit Record': '返水比例更改记录',
    'Site Platform Blacklist': '站点平台黑名单',
    'Telegram User': 'Telegram用户管理',
  },
  google: {
    google_auth_menu: '谷歌验证',
    download_install: '下载安装',
    add_key: '添加密钥',
    bind_verify: '绑定验证',
    popup_title: '身份验证器',
    popup_msg:
      '身份验证器是谷歌的一款动态口令工具,每隔30秒自动更新。在代理\n' +
      '        后台进行转账、提现、安全设置等敏感操作需要进行校验身份时,输\n' +
      '        入这6位身份验证码即可。',
    popup_hint: '身份验证器必须与代理账户配合使用。',
    i_know: '我知道了',
    download_way: '下载方法：',
    download_through_way: '1.通过下述地址下载：',
    ios_download: 'iOS下载',
    android_download: 'Android下载',
    ios_scan_download: 'IOS扫码下载',
    android_scan_download: 'Android扫码下载',
    if_cannot_download_you_can: '2.倘若无法下载，您可以在苹果商店搜索"',
    or_search_in_google: '"，或安卓应用商店搜索"',
    download_and_install: '身份验证器"下载安装。',
    keyin_pass: '输入密码并进行下一步',
    keyin_your_password: '输入登录密码',
    next_step: '下一步',
    tips_installed: '小提示：已下载安装，点击“下一步”继续即可。',
    qr_code: '二维码',
    secret_key: '密钥',
    add_step: '添加步骤',
    add_step_desc:
      '打开谷歌身份验证器，点击右下角的“+”，选择“手动输入密钥”，填入任意账户和上述秘钥绑定\n' +
      '      (扫描二维码可以自动添加)',
    check_example: '查看示例图',
    back: '返回',
    small_tips: '小提示：',
    small_tips_info_1:
      '1. 手机丢失或卸载身份验证后，密钥能够帮助您找回身份验证器，请妥善保管;',
    small_tips_info_2:
      '2.为了您的账户安全，绑定时请勿标注代理账户及代理后台地址。',
    keyin_6_digit_google: '请输入6位谷歌验证码',
    auth_code: '身份验证码',
    bind_now: '立即绑定',
    please_enter_password: '请输入您的密码',
    added_success: '谷歌验证码成功添加。',
    if_not_google_auth_then: '若未绑定Google Authenticator 则无需填写',
    google_auth_code: '请输入6位数谷歌验证码',
    register_count: '今日已注册数',
    register_limit: '今日可注册数',
  },
  posterType: {
    overall: '综合推广图',
    app: 'APP推广图',
    sponsor: '赞助推广图',
    gift: '赠送推广图',
    competition: '赛事推广图',
    event: '活动推广图',
    crypto: '虚拟币推广图',
    affiliate: '代理福利图',
  },
  siteAppVersion: {
    version: '版本',
    os: '操作系统',
    appType: '应用程序类型',
    apkType: '应用程序密码',
    fileUploadedSuccessfully: '文件上载成功',
    IOS: 'IOS',
    ANDROID: '安卓',
    ALL_SITE: '全站',
    SPORT: '体育',
    ESPORT: '电竞',
    NORMAL: '普通',
    NEW_KEY: '防毒',
    appUpload: '上载应用程序',
    editAppUpload: '编辑上载应用程序',
  },
  affiliateApk: {
    currentVersion: '当前版本',
    buildStatus: '构建状态',
    latestVersion: '最新版本',
    buildApk: '构建APK',
    buildAllApk: '构建全部APK',
    editParam: '编辑参数',
    status: {
      IN_QUEUE: '等待中',
      IN_PROGRESS: '构建中',
      SUCCESS: '成功',
    },
  },
  priviEvent: {
    status: '记录状态',
    cancel: '已取消',
    active: '活跃',
    settled: '已结算',
  },
  realtimeStatistics: {
    APP: 'APP',
    H5: 'H5',
    Mobile: 'Mobile',
    PC: 'PC',
    APPLY_WITHDRAW: '提现终审',
    APPLY_WITHDRAW_RISK: '风险终审'
  },
  loginDevice: {
    WEB: '网页',
    H5: 'H5',
    ANDROID: '安卓',
    IOS: '苹果',
  },
  result: {
    win: '赢',
    draw: '和',
    loss: '输',
  },
  freeze: {
    NORMAL: '普通冻结',
    TEMPORARY: '暂时冻结',
    PERMANENT: '永久冻结',
    UNFREEZE: '解除冻结',
  },
  reviewStatus: {
    PENDING: '未复核',
    APPROVED: '正确',
    REJECTED: '错误',
  },
  week: {
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: '有效投注佣金-捕鱼',
    AGENT_SHARE_BET_RATIO_SLOT: '有效投注佣金-老虎机',
    AGENT_SHARE_BET_RATIO_ESPORT: '有效投注佣金-电竞',
    AGENT_SHARE_BET_RATIO_LIVE: '有效投注佣金-真人',
    AGENT_SHARE_BET_RATIO_SPORT: '有效投注佣金-体育',
    AGENT_SHARE_BET_RATIO_CASUAL: '有效投注佣金-小游戏',
    AGENT_SHARE_BET_RATIO_POKER: '有效投注佣金-棋牌',
    AGENT_SHARE_BET_RATIO_HOTELCASINO: '有效投注佣金-酒店真人',
    AGENT_SHARE_PROFIT_RATIO_FISH: '净盈利佣金-捕鱼',
    AGENT_SHARE_PROFIT_RATIO_SLOT: '净盈利佣金-老虎机',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: '净盈利佣金-电竞',
    AGENT_SHARE_PROFIT_RATIO_LIVE: '净盈利佣金-真人',
    AGENT_SHARE_PROFIT_RATIO_SPORT: '净盈利佣金-体育',
    AGENT_SHARE_PROFIT_RATIO_CASUAL: '净盈利佣金-小游戏',
    AGENT_SHARE_PROFIT_RATIO_POKER: '净盈利佣金-棋牌',
    AGENT_SHARE_PROFIT_RATIO_HOTELCASINO: '净盈利佣金-酒店真人',
  },
  gameTypeRolloverSetting: {
    anyTypes: '所有游戏类型',
    specifyTypes: '指定游戏类型',
    specifyTypesAmount: '指定游戏类型及流水',
    excludeTypes: '排除游戏类型',
    specifyGameType: '单独满足游戏类型',
  },
  domainType: {
    LANDING: '落地页',
    DOWNLOAD: '下载页',
    AFFILIATE: '代理',
    HOME: '首页',
  },
  pmStatus: {
    UNSENT: '未发送',
    SENDING: '发送中',
    SENT: '已发送',
  },
  telegram: {
    userStatus: {
      PENDING_CONNECT: '待连接',
      PENDING_VERIFY: '待验证',
      ACTIVE: '已连接',
      STOPPED: '已停用',
    }
  },
  pmRedirectType: {
    NONE: '无跳转',
    INNER: '站内跳转',
    OUTER: '站外跳转',
  },
  vipCategory: {
    BRONZE: '青铜',
    SILVER: '白银·',
    GOLD: '黄金',
    PLATINUM: '铂金',
    DIAMOND: '钻石',
    WANGZE: '王者',
  },
  monitorTitle: {
    MEMBER_STATISTICS: '在线人数',
    DEPOSIT_FLUCTUATION: '存款波动',
    WITHDRAW_FLUCTUATION: '提款波动',
    BONUS_FLUCTUATION: '优惠波动',
  },
  error: {
    400: '请求无效',
    401: '用户未授权',
    403: '禁止访问',
    404: '找不到网页',
    405: '方法不被允许',
    500: '系统错误',
    501: '请求ID重复',
    502: '无法找到伺服器',
    504: '请求太频繁',
    601: '令牌验证失败',
    602: '令牌已逾期',
    603: '无法找到令牌',
    604: '账号已登录',
    605: '账号已登出',
    606: '所选角色已被分配至用户，请先将此角色取消分配再尝试',
    607: '当用户类别不是站点管理员，站点不能为空',
    700: '新密码不能与旧密码相同',
    701: '参数已存在',
    707: '余额不足',
    800: '验证码失败',
    801: '验证码已失效',
    900: '无法找到会员信息',
    901: '无法找到此代理信息',
    912: '用户名不存在',
    922: '你的账号还未连接到VCall',
    1000: '登录名或密码错误',
    1001: '登录失败, 账号已被禁用',
    1005: '登录失败, 账号已被封锁, 请于明日再尝试',
    1006: '登录失败, 账号不存在',
    1007: '该提款订单已经提交, 请于20分钟后在尝试',
    1008: '您已经绑定谷歌验证，请输入谷歌验证码',
    1009: '谷歌验证码输入错误',
    1100: 'ADMIN账号不能登录',
    1101: '账号不属于此站点',
    9000: '创建定时任务失败',
    9001: '更新定时任务失败',
    9002: '删除定时任务失败',
    10009: '请输入至少一个搜索条件',
    10010: '公告类型名称已存在',
    10011: '公告标题已存在',
    10012: '公告类型不存在',
    10013: '此公告类型已被现有公告使用, 请先删除公告',
    10020: '广告标题已存在',
    10021: '无法找到此广告',
    10030: '优惠名称已存在',
    10031: '无法找到此优惠',
    10032: '图像档案格式错误',
    10033: '广告图像上传失败',
    10034: '优惠图像上传失败',
    10040: '风险等级名称已存在',
    10041: '风险等级颜色已存在',
    1004: '您已经绑定谷歌验证，请输入谷歌验证码',
    10050: 'IP标签已存在',
    10051: 'IP标签不存在',
    10110: '平台获取待处理注单异常',
    10111: '平台更新待处理注单异常',
    11000: '代理不存在',
    11006: '站点代理分红已存在',
    11007: '站点代理分红不存在',
    11008: '代理信息不存在',
    11009: '代理分红必须多于下级代理分红',
    11010: '此代理不能拥有下级代理',
    11011: '上级代理没有设置返水比例',
    11012: '上级代理没有设置游戏类的返水比例',
    11013: '下级代理返水比例不能超过上级',
    11014: '下级代理的返水比例总和不能超过上级的返水比例',
    11015: '此代理没有设置返水比例',
    11016: '玩家返水比例不能超过代理返水比例',
    12000: '优惠名称已存在',
    12001: '优惠代码已存在',
    12002: '请输入优惠组别',
    12009: "流水格式错误，请检查一遍。",
    12010: '货币名称已存在',
    12011: '此站点已设置汇率',
    12020: '此记录状态并不是 SENDING',
    12030: '站点名称已存在',
    12031: '站点代码已存在',
    12032: '此站点为母站点',
    12033: '母站点不可更改',
    12034: '支付名称已存在',
    12035: '支付代码已存在',
    12036: '优惠不存在',
    12037: '支付ID不存在',
    12038: '金额不可多于最高奖金',
    12039: '会员已被类入此优惠的黑名单',
    12040: '支付名称不存在',
    12041: '支付编码已存在',
    12042: '商户号已存在',
    12050: '银行不存在',
    12051: '银行名称已存在',
    12052: '银行代码已存在',
    12053: '银行卡识别码已存在',
    12054: '银行卡已存在',
    12055: '图片已被使用',
    14000: '用户名已经存在',
    14001: '手机号码已经存在',
    14002: '邮箱已经存在',
    14003: '代理代码不存在',
    14004: '代理代码不属于此站点',
    14005: '代理分红必须少于上级代理分红',
    14006: '代理收入必须少于上级代理收入',
    14007: '代理注册申请还未被批准',
    14008: '代理分红必须多于下级代理分红',
    14009: '代理收入必须多于下级代理收入',
    14011: '代理代码已存在',
    14012: '只能编辑比上级低的代理等级',
    14013: '此代理已有下线，不能编辑更低的代理等级',
    14014: '电话号码不正确 / 电话号码不规范',
    14100: '代理并无此下级会员',
    14101: '代理无权结算此佣金',
    14110: '平台已存在于此站点',
    14111: '状态必须为 CLOSE 为期一个月方能删除',
    14112: '此平台站点的状态为 CLOSE 的时间少于一个月, 请于一个月后再删除',
    14120: '此会员组别, 币别, 站点和设备设置已存在',
    14121: '最低提款金额不能少于0',
    14122: '最高提款金额不能少于0',
    14123: '最高提款次数不能少于0',
    14124: '每日最高提款金额不能少于0',
    14125: '最低提款金额不能多于最高金额',
    14126: '最高提款金额不能少于最低金额',
    14127: '每日最高提款金额不能少于最高提款金额',
    14130: '平台账号不存在',
    14131: '平台账号已用于其他平台站点',
    15000: 'VIP名称已存在于此站点',
    15001: 'VIP不存在',
    15002: 'VIP已被现有VIP使用, 故无法删除',
    15003: 'VIP返水规则已存在于此VIP级别和游戏类型',
    15004: 'VIP返水规则不存在',
    15005: 'VIP返水已派发',
    15008: '会员特殊返水规则已存在于此会员和游戏类型',
    15009: '会员特殊返水规则不存在',
    15010: '会员组别名称已存在于此站点',
    15012: '会员组别不存在',
    15020: '存款记录不存在',
    15021: '此会员组别, 支付类型, 站点和设备设置已存在',
    15030: '无法处理该 JSON 数据',
    15040: '提款显示名称已存在',
    15041: '提款显示不存在',
    15050: '支付银行已存在',
    15051: '支付代码已存在',
    15060: '提款平台不存在',
    15061: '提款平台银行已存在',
    15062: '提款平台代码已存在',
    15063: '提款记录不存在',
    15064: '提款平台不存在',
    15065: '提款卡不存在',
    16000: '优惠不存在',
    16001: '开彩号码必须为三位数',
    16002: '该日期已有开彩结果',
    16010: '团队英文名称已存在',
    16011: '团队不存在',
    16012: '奖池未设置',
    17010: '团队投票记录不存在',
    17011: '团队投票记录已被取消',
    18000: '域名已存在',
    18001: '代理域名不存在',
    18002: '找不到 Facebook 像素以更改',
    18003: '此代理已经设置 Facebook 像素',
    19000: '此提款银行卡已被列入黑名单',
    19002: '红包雨时间有重叠',
    19003: '红包刷新时间有重叠',
    19004: '尾数有重复',
    19101: '广告标题已存在',
    19102: '广告不存在',
    19103: '此站点的广告已经打开，请先关闭当前打开的广告',
    20000: '短信设置站点已存在',
    20100: '邮件设置站点已存在',
    20201: '错误的类型',
    20202: '请输入 ‘正常’ / ‘冻结’ ',
    20203: '生日格式错误',
    20204: '找不到风控等级，请输入正确的风控等级(例：1)',
    20205: '找不到财务等级，请输入正确的财务等级(例：1)',
    20206: '请输入 ’测试‘ / ’普通‘ / ’三方‘ / ’代理‘',
    20400: '无法查询流水记录',
    21000: '此游戏不存在',
    21100: '此玩家轮盘票数已达上限',
    21101: '此站点无轮盘优惠',
    22001: '会员VIP等级最高奖金为 $',
    21005: '应用程序已存在',
    21006: '操作系统和上载应用程序不匹配',
    22003: '赛事已存在',
    30000: 'PG注单设置失败',
    24003: '结果已结算',
    27000: '钥匙数量不足',
    31000: '所选日期还有赛事未结束，请待所有赛事结束了再结算',
    31001: '所选日期无赛事',
    40002: '唤醒记录不存在',
    40003: '会员已被唤醒',
    45300: '玩家于此记录时间的虚拟数据已存在',
    45301: '玩家于此记录时间的虚拟数据不存在',
    45302: '玩家不属于任何代理'
  },
}
