import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const getMemberRolloverRecords = (rollover) => {
  return https().request("/rolloverRecord", Method.GET, rollover, ContentType.form);
};

export const getTotal = (rollover) => {
  return https().request("/rolloverRecord/total", Method.GET, rollover, ContentType.form);
};

export const cancelRollover = (rollover) => {
  return https().request("/rolloverRecord/cancel?_method=PUT", Method.POST, rollover, ContentType.form);
}

export const hasOngoingRecords = (rollover) => {
  return https().request("/rolloverRecord/hasOngoingRecord", Method.GET, rollover, ContentType.form);
};

export const cancelAllRollover = (rollover) => {
  return https().request("/rolloverRecord/cancelAll?_method=PUT", Method.POST, rollover, ContentType.form);
}

export const getRolloverBets = (siteId, memberId, rolloverId) => {
  return https().request(`/member/${memberId}/rollover-bet`, Method.GET, { siteId: siteId, rolloverId: rolloverId }, ContentType.form)
}
