/* eslint-disable */
import HttpClient from "axios-mapper";
import { ElMessage } from "element-plus";
import { useStore } from "@/store";
import { ResponseCode } from "../api/response";
import _cloneDeep from 'lodash/cloneDeep';
import i18n from "../i18n/index";
import { globals } from '../main.js'
import axios from 'axios';

const toRawType = (value) => {
  return Object.prototype.toString.call(value).slice(8, -1)
}

const clearQueryStr = (param) => {
  return param.split("&").filter(item => item.split("=")[1] !== '').join("&");
}

const clearEmptyParam = (config) => {
  if (config.headers["Content-type"] && config.headers["Content-type"].includes("json")) {
    return;
  }
  ['data', 'params'].forEach(item => {
    if (config[item]) {
      const rawType = toRawType(config[item])
      if (['Object'].includes(rawType)) {
        const keys = Object.keys(config[item])
        if (keys.length) {
          keys.forEach(key => {
            if (['', undefined, null].includes(config[item][key])) {
              if (['Object'].includes(rawType)) {
                config[item] = _cloneDeep(config[item])
                delete config[item][key]
              }
            }
          })
        }
      } else if (['String'].includes(rawType)) {
        config[item] = clearQueryStr(config[item]);
      }
    }
  })
}
const onRequest = (config) => {
  clearEmptyParam(config);

  const host = window.location.hostname;
  if (host.includes("www.k4y0sr02")) {
    // debugger;
    console.log("k4y0sr0 12");
    // config.withCredentials = true

    const cfAuthori = getCookieValue('CF_Authorization');
    console.log(cfAuthori);

    const cfSession = getCookieValue('CF_AppSession');
    console.log(cfSession);

    const cfBinding = getCookieValue('CF_Binding');
    console.log(cfBinding);

    config.withCredentials = true;

    config.headers['Cookie'] = `CF_Authorization=${cfAuthori}; CF_AppSession=${cfSession}; CF_Binding=${cfBinding};`;
    // console.log(config);
  }
  return config;
}

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // 如果Cookie不存在，返回null
}

const onResponse = (response) => {
  let res = response.data;
  if (typeof response.data === "string") {
    res = JSON.parse(response.data);
  }
  if (res.code !== ResponseCode.SUCCESS) {
    const store = useStore()
    // const siteId = store.state.user.siteId
    if(res.code === ResponseCode.ERROR_FORBIDDEN2 || res.code === ResponseCode.ERROR_FORBIDDEN3){
      // debugger;
      if(res.data){
        sessionStorage.setItem('myIPAddress', res.data)
      }
      if(res.ip){
        sessionStorage.setItem('myIPAddress', res.ip)
      }
      if(res.loginName){
        sessionStorage.setItem('myloginName', res.loginName)
      }

      window.location.href = '/403';
      return;
    }
    if (res.code === ResponseCode.ERROR_UNAUTHORIZED) {
      ElMessage({
        message: "Duplicated login.",
        type: "error"
      });
      location.reload();
    } else if (res.code === ResponseCode.ERROR_TOKEN_EXPIRED ||
      res.code === ResponseCode.ERROR_TOKEN_MISSED ||
      res.code === ResponseCode.ERROR_NAME_EXIST ||
      res.code === ResponseCode.ERROR_TOKEN_LOGGED ||
      res.code === ResponseCode.ERROR_TOKEN_FAILED) {
      ElMessage({
        message: "Please re-login. Code: " + res.code,
        type: "error"
      });
      location.reload();
    } else {
      ElMessage({
        message: i18n.global.t('error.' + res.code) + (res.data && res.data.parameter ? res.data.parameter : "") || "Error",
        type: "error"
      });
    }
    throw new Error(res.message || "Error");
  } else {
    return response;
  }
};

const onResponseError = (error) => {
  ElMessage({
    message: error.message,
    type: "error"
  });
  return Promise.reject(error);
};

const https = (timeout) => {
  let baseApi = globals.$baseApi;
  if (!baseApi) {
    const sessionUrl = sessionStorage.getItem("baseApi");
    if (sessionUrl) {
      baseApi = sessionUrl;
    } else {
      baseApi = process.env.VUE_APP_BASE_API.indexOf(",") > -1 ? process.env.VUE_APP_BASE_API.split(",")[0] : process.env.VUE_APP_BASE_API;
    }
  }


  const token = useStore().state.user.token;
  var config = {
    baseURL: baseApi,
    headers: {
      Authorization: `Bearer ${token}`
    },
    timeout: timeout | process.env.VUE_APP_TIMEOUT,
  };
  const httpClient = new HttpClient(config);
  httpClient.httpClient.interceptors.request.use(onRequest);
  httpClient.httpClient.interceptors.response.use(onResponse, onResponseError);
  return httpClient;
};

export default https;
