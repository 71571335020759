import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getSystemPaymentList = (systemPayment) => {
  return https().request("/system-payment", Method.GET, systemPayment, ContentType.form);
};

export const createSystemPayment = (systemPayment) => {
  return https().request("/system-payment", Method.POST, systemPayment, ContentType.json);
};

export const updateSystemPayment = (systemPayment) => {
  return https().request(`/system-payment/update?_method=PUT`, Method.POST, systemPayment, ContentType.json);
};

export const updateSystemPaymentStatus = async (id, status) => {
  await https().request(`/system-payment/${id}/status?_method=PUT`, Method.POST, { status: status }, ContentType.form);
};

export const updatePaymentPlatform = () => {
  return https().request(`/system-payment/updatePaymentPlatform`, Method.GET, {}, ContentType.form);
};

export const getSystemPaymentById = (id) => {
  return https().request(`/system-payment/${id}/systemPayment`, Method.GET, {}, ContentType.form);
};

export const getSystemPaymentListForDeposit = (site) => {
  return https().request("/system-payment/depositRecord", Method.GET, { site: site }, ContentType.form);
};

export const copyPayment = async (systemPayment) => {
  await https().request(`/system-payment/copy?_method=PUT`, Method.POST, systemPayment, ContentType.post);
};
