import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const getSummaryReport = data => {
  return https().request(
    '/report/getSummaryReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getDorisSummaryReport = data => {
  return https().request(
    '/report/getSummaryReport/list',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryReport = data => {
  return https().request(
    '/report/requestSummaryReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getTotalSummaryReport = data => {
  return https().request(
    '/report/getTotalSummaryReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getDorisTotalSummaryReport = data => {
  return https().request(
    '/report/getTotalSummaryReport/list',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getMemberReport = data => {
  return https().request(
    '/report/getMemberReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getMemberProfitRanking = data => {
  return https().request(
    '/report/getMemberProfitRanking',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportWithdrawalReviewReport = data => {
  return https().request(
    '/report/requestWithdrawalReviewReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getTotalWithdrawReview = data => {
  return https().request(
    '/report/getTotalWithdrawReview',
    Method.GET,
    data,
    ContentType.form
  )
}

export const addReview = data => {
  return https().request(
    '/report/addReview',
    Method.POST,
    data,
    ContentType.json
  )
}

export const getSummaryRegisterReport = data => {
  return https().request(
    '/report/getSummaryRegisterReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryRegisterReport = data => {
  return https().request(
    '/report/requestSummaryRegisterReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getSummaryFdpReport = data => {
  return https().request(
    '/report/getSummaryFdpReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryFdpReport = data => {
  return https().request(
    '/report/requestSummaryFdpReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getSummaryDepositReport = data => {
  return https().request(
    '/report/getSummaryDepositReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryDepositReport = data => {
  return https().request(
    '/report/requestSummaryDepositReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getSummaryWithdrawReport = data => {
  return https().request(
    '/report/getSummaryWithdrawReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryWithdrawReport = data => {
  return https().request(
    '/report/requestSummaryWithdrawReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getSummaryActiveReport = data => {
  return https().request(
    '/report/getSummaryActiveReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryActiveReport = data => {
  return https().request(
    '/report/requestSummaryActiveReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getSummaryMemberReport = data => {
  return https().request(
    '/report/getSummaryMemberReport',
    Method.GET,
    data,
    ContentType.form
  )
}

export const getExportSummaryMemberReport = data => {
  return https().request(
    '/report/requestSummaryMemberReportExport',
    Method.GET,
    data,
    ContentType.form
  )
}
