import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getGameMatch = (query) => {
  return https().request("/game-match", Method.GET, query, ContentType.form);
};

export const createGameMatch = (gameMatch) => {
  return https().request("/game-match", Method.POST, gameMatch, ContentType.form);
};

export const updateGameMatch = (id, gameMatch) => {
  return https().request(`/game-match/${id}?_method=PUT`, Method.POST, gameMatch, ContentType.form);
};

export const updateGameMatchStatus = (id) => {
  return https().request(`/game-match/${id}/status?_method=PUT`, Method.POST);
};

export const deleteGameMatch = (id) => {
  return https().request(`/game-match/${id}?_method=DELETE`, Method.POST);
};

export const getGameMatchRecord = (query) => {
  return https().request("/game-match-record", Method.GET, query, ContentType.form);
};

export const settleRecord = (record) => {
  return https().request(`/game-match-record/${record.id}/settle?_method=PUT`, Method.POST, { amount: record.amount }, ContentType.form);
};

export const cancelRecord = (record) => {
  return https().request(`/game-match-record/${record.id}/cancel?_method=PUT`, Method.POST, { remark: record.remark }, ContentType.form);
};
